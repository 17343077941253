import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-calls/global-variables';
import { HttpCall } from '../../http/http-calls/http-calls';

@Injectable({
  providedIn: 'root'
})
export class SubClassApplicationService {
  constructor(
    private globalApiVariables: GlobalApiVariables,
    private http: HttpCall) {
  }



  public GetSubClassesByClassId(classId: number) {
    var data = this.http.GetAsync(
      true,
      this.globalApiVariables.ApiGetSubClassByClassId,
      classId
    );

    return data;
  }


}
