<div (click)="ClickNotification()" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4 notification-card">

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0">
    <span *ngIf="notification.name && notification.name.length>0" class="trademark-title">
      {{notification.name}}
    </span>
    <img *ngIf="notification.logo" src="{{notification.logo}}" alt="logo" class="logo">
  </div>

  <div class="col-12 p-0 message">
    <span>{{notification.message}}</span>
  </div>

  <div class="col-12 p-0 mt-2 description">
    <span>{{notification.description}}</span>
  </div>

</div>
