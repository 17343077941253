<div class="widget-pie-chart col-xl-12">
  <div class="widget-pie-chart__header row p-0 m-0">
    <!-- Chart Title-->
    <span class="widget-pie-chart__title col-12 p-0 m-0">
      {{ PieChartData.title }}
    </span>

    <!-- Description Title-->
    <span class="widget-pie-chart__desc col-12 p-0 mt-3">
      {{ PieChartData.description }}
    </span>
  </div>

  <!-- Chart & Legends -->
  <div class="widget-pie-chart-content col-xl-12 row m-0 p-0 mt-5">
    <!-- Chart -->
    <div class="widget-pie-chart-chart col-xl-6 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <canvas #chart style="height: 150px; width: 270px;"></canvas>
    </div>

    <!-- Legends List-->
    <div class="widget-pie-chart-legends col-xl-6 col-lg-8 col-md-8 col-sm-8 col-xs-12 p-0 row m-0">
      <!-- Legend Item-->
      <div
        class="widget-pie-chart-legend row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 offset-xs-0 m-0 p-0"
        *ngFor="let values of PieChartData.details">
        <div
          class="col-xl-12 col-lg-8 col-md-6 col-sm-8 col-xs-8 offset-xl-0 offset-lg-2 offset-md-3 offset-sm-2 offset-xs-2 row m-0 p-0">
          <div class="widget-pie-chart-legend-colorbar col-xl-2 col-lg-4 col-md-2 col-sm-4 col-xs-4 m-0 p-0">
            <div class="color-box" [ngStyle]="{
                'background-color': values.backgrounds
              }"></div>
          </div>
          <div class="widget-pie-chart-legend-value col-xl-10 col-lg-8 col-md-10 col-sm-8 col-xs-8 ">
            {{ values.value }} % {{ values.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
