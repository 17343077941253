<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 subclass-dialog">


  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
    <span class="title">SubClasses</span>
  </div>

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
    <span class="description">You are choosing subclasses for this specific class</span>
  </div>

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
    <mat-button-toggle-group (change)="ChangeMatToggle($event.value)" [(ngModel)]="toggleValue" name="classes"
      aria-label="Classes">
      <mat-button-toggle *ngFor="let class of data" [value]="class.id">{{class.name}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div *ngIf="isLoading" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 mb-5 text-center">
    <mat-spinner [diameter]=50 style="margin-right: auto; margin-left: auto;"></mat-spinner>
  </div>

  <ng-container *ngIf="!isLoading">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 table-container">

      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
      </mat-form-field>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <!-- <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox> -->
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox color="primary" (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="basicNumber">
          <th mat-header-cell *matHeaderCellDef> Basic number </th>
          <td mat-cell *matCellDef="let element"> {{element.basicNumber}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
        </tr>
      </table>

    </div>

    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]=false>Cancel</button>
      <div class="action-button">
        <button mat-button (click)="SetSubCourses()"
          [disabled]="data.length == 1 && (!selection.selected || selection.selected.length ==0) ? true :
          data.length - 1 == classesRequest.length  && selection.selected && selection.selected.length > 0 ? false : true" class="ml-2 mr-2">Choose</button>
      </div>
    </mat-dialog-actions>
  </ng-container>
</div>
