import { Injectable } from '@angular/core';
import { ExtraServicesApplicationService } from '../../services/extra-services/extra-services-application-service.service';

@Injectable({
  providedIn: 'root'
})

export class ExtraServiceViewModel {
  constructor(
    private service: ExtraServicesApplicationService) {
  }



  /**
   * @name GetAllAvailableExtraServices
   * Get all available extra services
   */
  public GetAllAvailableExtraServices() {
    var data = this.service.GetAllAvailableExtraServices();
    return data;
  }



  /**
   * @name CreateExtraService
   * @param body
   * Create extra service
   */
  public CreateExtraService(body: any) {
    var data = this.service.CreateExtraService(body);
    return data;
  }




  /**
   * @name DeleteExtraService
   * @param extraServiceId
   * Delete extra service
   */
  public DeleteExtraService(extraServiceId: number) {
    var data = this.service.DeleteExtraService(extraServiceId);
    return data;
  }


  /**
   * @name GetExtraServiceById
   * @param extraServiceId
   * Get extra service by id
   */
  public GetExtraServiceById(extraServiceId: number) {
    var data = this.service.GetExtraServiceById(extraServiceId);
    return data;
  }


  /**
   * @name UpdateExtraService
   * @param id
   * @param body
   * Update extra service
   */
  public UpdateExtraService(id: number, body: any) {
    var data = this.service.UpdateExtraService(id, body);
    return data;
  }


}
