import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-calls/global-variables';
import { HttpCall } from '../../http/http-calls/http-calls';

@Injectable({
  providedIn: 'root'
})
export class NotificationApplicationService {


  constructor(
    private globalApiVariables: GlobalApiVariables,
    private http: HttpCall) {
  }


  /**
   * @name GetNotificationsByUserId
   * @param userId
   * @returns data
   * Get notifications by user id
   */
  public GetNotificationsByUserId(userId: number) {
    var data = this.http.GetAsync(
      true,
      this.globalApiVariables.ApiGetNotifications,
      userId
    );

    return data;
  }


  /**
   * @name UpdateNotificationAsSeen
   * @param notificationId
   * @returns data
   * Update notification as seen
   */
  public UpdateNotificationAsSeen(notificationId: number) {
    var data = this.http.PutAsync(
      true,
      null,
      this.globalApiVariables.ApiUpdateNotificationAsSeen,
      notificationId
    );

    return data;
  }

}
