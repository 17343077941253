import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';

@Component({
  selector: 'preview-files-dialog',
  templateUrl: './preview-files-dialog.component.html',
  styleUrls: ['./preview-files-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class PreviewFilesDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }



  OpenUrl(url: string) {
    LoggerHelper.log(url);
    window.open(url, "_blank");
  }

}
