import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CachingHelper } from 'src/app/core/helpers/caching/caching';
import { CachingKeys } from 'src/app/core/helpers/caching/caching-keys';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { NavigationKeys } from 'src/app/shared/helpers/navigation-keys';

@Injectable()
export class AuthGuardService implements CanActivate {


  constructor(public _router: Router) { }

  canActivate(): boolean {

    if (JSON.parse(CachingHelper.getItemFromCache(CachingKeys.UserSession))) {
      LoggerHelper.log('Is authenticated');
      return true;
    }
    else {
      LoggerHelper.log('Redirect to login');
      this._router.navigate([NavigationKeys.SignInPage]);
      return false;
    }
  }
}
