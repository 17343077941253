import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { CountryFullDetailsRequestModel } from 'src/app/core/models/country/request/country-full-details-request.model';
import { CountryFullDetailsResponseModel } from 'src/app/core/models/country/response/country-full-details-response.model';
import { RequestTypeFeeRequestModel } from 'src/app/core/models/request-type/request/request-type-fee-request.model';
import { RequestTypeResponseModel } from 'src/app/core/models/request-type/response/request-type-response.model';
import { SidebarHandlerModel } from 'src/app/core/models/sidebar/sidebar.model';
import { SharedService } from 'src/app/core/services/shared/shared-service.service';
import { CountryViewModel } from 'src/app/core/viewmodel/country/country.viewmodel';
import { EncryptionDecryptionHelper } from 'src/app/shared/helpers/encryption-decryption';
import { EncryptionDecryptionKeys } from 'src/app/shared/helpers/encryption-decryption-keys.helper';
import { MainKeys } from 'src/app/shared/helpers/main-keys';
import { NavigationKeys } from 'src/app/shared/helpers/navigation-keys';
import { SnackBar } from 'src/app/shared/snack-bar/snack-bar.component';

@Component({
  selector: 'manage-country-detail',
  templateUrl: './manage-country-detail.component.html',
  styleUrls: ['./manage-country-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ManageCountryDetailComponent implements OnInit {

  ngOnInit() {
    window.scroll(0, 0);
    this.ChangeSideBarActive();
    this.GetIdFromUrl();
  }


  ChangeSideBarActive() {
    var sidebarHandler = new SidebarHandlerModel();
    sidebarHandler.dashboard = false;
    sidebarHandler.comprehensiveStudy = false;
    sidebarHandler.registrationRequest = false;
    sidebarHandler.certificationRequest = false;
    sidebarHandler.discount = false;
    sidebarHandler.searches = false;
    sidebarHandler.trademarkTransfer = false;
    sidebarHandler.renewalCertificate = false;
    sidebarHandler.country = true;
    sidebarHandler.user = false;
    sidebarHandler.extraService = false;
    sidebarHandler.watchPackage = false;
    sidebarHandler.emailRequest = false;
    this.sharedService.SideBarHandler.emit(sidebarHandler);
  }

  isLoading: boolean = true;
  spinnerLoading: boolean = false;

  countryId: number;

  countryName: string;
  region: string;
  flagLink: string;

  america: string = MainKeys.america;
  europe: string = MainKeys.europe;
  africa: string = MainKeys.africa;
  asia: string = MainKeys.asia;
  oceania: string = MainKeys.oceania;

  editMode: boolean;

  serviceCount: number = 0;
  countryRequest: CountryFullDetailsRequestModel;
  requestTypes: RequestTypeResponseModel[];



  countryFullDetails: CountryFullDetailsResponseModel;

  comprehensiveAdvisoryRequestType: RequestTypeResponseModel;
  registrationRequestRequestType: RequestTypeResponseModel;

  constructor(
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private viewModel: CountryViewModel,
    private _snackBar: SnackBar,
    private _router: Router) {
  }



  GetIdFromUrl() {
    this.activatedRoute.queryParams
      .subscribe((params) => {
        this.countryId = +EncryptionDecryptionHelper.DecryptStringAES(
          EncryptionDecryptionKeys.EncryptDecryptCommonKey,
          EncryptionDecryptionKeys.IVCommonKey, params['countryId']);
        LoggerHelper.log('country id = ' + this.countryId);
      });

    if (this.countryId > 0) {
      this.editMode = true;
    }
    else {
      this.editMode = false;
    }


    this.LoadData();
  }



  async LoadData() {
    if (this.editMode) {
      await this.GetCountryDetails(this.countryId);
    }
    this.GetPaidRequestType();
  }


  SaveCountry() {
    if (!this.countryName ||
      this.countryName.length == 0 ||
      !this.region ||
      this.region.length == 0 ||
      !this.flagLink ||
      this.flagLink.length == 0) {
      this._snackBar.ShowWarning("Please fill the required data");
    }
    else {
      this.countryRequest = new CountryFullDetailsRequestModel;
      this.countryRequest.name = this.countryName;
      this.countryRequest.flag = this.flagLink;
      this.countryRequest.region = this.region;
      this.countryRequest.requestTypeFees = [];
      this.serviceCount = 0;
      this.sharedService.GetRequestTypes.emit(true);
    }
  }



  GetPaidRequestToSaveCountry(requestTypeFee: RequestTypeFeeRequestModel) {
    this.serviceCount++;
    if (requestTypeFee) {
      this.countryRequest.requestTypeFees.push(requestTypeFee);
    }


    if (this.serviceCount == this.requestTypes.length) {
      if (!this.countryRequest.requestTypeFees) {
        this._snackBar.ShowWarning("Please fill at least the comprehensive advisory and the registration request")
      }
      else if (this.countryRequest.requestTypeFees &&
        (!this.countryRequest.requestTypeFees.find(f => f.requestTypeId == this.comprehensiveAdvisoryRequestType.id) ||
          !this.countryRequest.requestTypeFees.find(f => f.requestTypeId == this.registrationRequestRequestType.id))) {
        this._snackBar.ShowWarning("Please fill at least the comprehensive advisory and the registration request")
      }
      else {
        LoggerHelper.log("--------------- Done ------------------");
        LoggerHelper.log(this.countryRequest);

        if (!this.editMode) {
          this.CreateCountry(this.countryRequest);
        }
        else if (this.editMode) {
          this.UpdateCountry(this.countryId, this.countryRequest);
        }
      }


    }

  }


  GetMainRequestType(requestTypes: RequestTypeResponseModel[]) {
    requestTypes.forEach(element => {
      if (element.description.toLocaleLowerCase() == MainKeys.comprehensiveAdvisory.toLocaleLowerCase()) {
        this.comprehensiveAdvisoryRequestType = element;
      }
      else if (element.description.toLocaleLowerCase() == MainKeys.registrationRequest.toLocaleLowerCase()) {
        this.registrationRequestRequestType = element;
      }
    });
  }




  SetCountryDetailsInfo(country: CountryFullDetailsResponseModel) {
    this.countryName = country.name;
    this.region = country.region;
    this.flagLink = country.flag;
  }


  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  START API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------



  /**
   * @name GetCountryDetails
   * @param countryId
   * Get country details
   */
  async GetCountryDetails(countryId: number) {
    this.isLoading = true;
    await this.viewModel
      .GetCountryDetails(countryId)
      .toPromise()
      .then((result) => {
        LoggerHelper.log(
          'New Data inside GetCountryDetails : ' + JSON.stringify(result)
        );
        this.countryFullDetails = result;
        this.SetCountryDetailsInfo(this.countryFullDetails);
      })
      .catch((error) => {
        LoggerHelper.log('Error :: ' + error);
        this._snackBar.ShowWarning('There is an issue with your connection');

      })
      .finally(() => {
      });
  }


  /**
   * @name GetPaidRequestType
   * @returns data
   * Get paid request types
   */
  async GetPaidRequestType() {
    this.isLoading = true;
    await this.viewModel
      .GetPaidRequestType()
      .toPromise()
      .then((result) => {
        LoggerHelper.log(
          'New Data inside GetPaidRequestType : ' + JSON.stringify(result)
        );

        this.requestTypes = result;
        this.GetMainRequestType(this.requestTypes);
      })
      .catch((error) => {
        LoggerHelper.log('Error :: ' + error);
        this._snackBar.ShowWarning('There is an issue with your connection');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }




  /**
   * @name CreateCountry
   * @param body
   * Create country
   */
  async CreateCountry(body: CountryFullDetailsRequestModel) {
    this.spinnerLoading = true;
    await this.viewModel
      .CreateCountry(JSON.stringify(body))
      .toPromise()
      .then((result) => {
        LoggerHelper.log(
          'New Data inside CreateCountry : ' + JSON.stringify(result)
        );

        this._router.navigate([NavigationKeys.Admin + '/' + NavigationKeys.AdminCountry])
      })
      .catch((error) => {
        LoggerHelper.log('Error :: ' + error);
        this._snackBar.ShowWarning('There is an issue with your connection');

      })
      .finally(() => {
        this.spinnerLoading = false;
      });
  }



  /**
   * @name UpdateCountry
   * @param countryId
   * @param body
   * Update country
   */
  async UpdateCountry(countryId: number, body: CountryFullDetailsRequestModel) {
    this.spinnerLoading = true;
    await this.viewModel
      .UpdateCountry(countryId, JSON.stringify(body))
      .toPromise()
      .then((result) => {
        LoggerHelper.log(
          'New Data inside UpdateCountry : ' + JSON.stringify(result)
        );

        this._router.navigate([NavigationKeys.Admin + '/' + NavigationKeys.AdminCountry])

      })
      .catch((error) => {
        LoggerHelper.log('Error :: ' + error);
        this._snackBar.ShowWarning('There is an issue with your connection');

      })
      .finally(() => {
        this.spinnerLoading = false;
      });
  }

  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  End API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------

}
