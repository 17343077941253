import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ManageCountryDetailComponent } from 'src/app/admin/country-workflow/manage-country-detail/manage-country-detail.component';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { RequestFeeRequestModel } from 'src/app/core/models/request-type/request/request-fee-request.model';
import { RequestTypeFeeRequestModel } from 'src/app/core/models/request-type/request/request-type-fee-request.model';
import { RequestTypeFeeResponseModel } from 'src/app/core/models/request-type/response/request-type-fee-response.model';
import { SharedService } from 'src/app/core/services/shared/shared-service.service';

@Component({
  selector: 'request-type-fee',
  templateUrl: './request-type-fee.component.html',
  styleUrls: ['./request-type-fee.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class RequestTypeFeeComponent implements OnInit {
  ngOnInit() {
    this.GetRequestType();
    this.FillInEditMode();
  }


  @Input() countryId: number;
  @Input() requestTypeId: number;
  @Input() requestType: string;
  @Input() forBothFee: boolean;
  @Input() editMode: boolean;
  @Input() requestTypeFees: RequestTypeFeeResponseModel[];


  primaryGovernmentFee: number;
  primaryProfessionalServices: number;

  secondaryGovernmentFee: number;
  secondaryProfessionalServices: number;

  bothGovernmentFee: number;
  bothProfessionalServices: number;


  constructor(
    private sharedService: SharedService,
    @Inject(ManageCountryDetailComponent) private parent: ManageCountryDetailComponent) {

  }



  FillInEditMode() {
    if (this.editMode && this.requestTypeFees) {
      var requestTypeFee = this.requestTypeFees.find(f => f.requestTypeId == this.requestTypeId);
      if (requestTypeFee && requestTypeFee.fees && requestTypeFee.fees.length > 0) {
        requestTypeFee.fees.forEach(element => {
          if (element.isBothClasses) {
            this.bothGovernmentFee = element.governmentFee;
            this.bothProfessionalServices = element.professionalServices;
          }
          else if (element.isPrimaryClass) {
            this.primaryGovernmentFee = element.governmentFee;
            this.primaryProfessionalServices = element.professionalServices;
          }
          else if (element.isSecondaryClass) {
            this.secondaryGovernmentFee = element.governmentFee;
            this.secondaryProfessionalServices = element.professionalServices;
          }
        });
      }
    }
  }

  /**
   * @name SelectPrimaryGovernmentFee
   * @param primaryGovernmentFee
   * Select government fee
   */
  SelectPrimaryGovernmentFee(primaryGovernmentFee: number) {
    LoggerHelper.log(primaryGovernmentFee);
    if (primaryGovernmentFee.toString().length == 0) {
      this.primaryGovernmentFee = undefined;
    }
    else {
      if (!isNaN(primaryGovernmentFee)) {
        if (primaryGovernmentFee >= 0) {
          this.primaryGovernmentFee = primaryGovernmentFee;
        }
      }
      else {
        this.primaryGovernmentFee = undefined;
      }
    }
  }



  /**
   * @name SelectPrimaryProfessionalServices
   * @param primaryProfessionalServices
   * Select professional services
   */
  SelectPrimaryProfessionalServices(primaryProfessionalServices: number) {
    LoggerHelper.log(primaryProfessionalServices);
    if (primaryProfessionalServices.toString().length == 0) {
      this.primaryProfessionalServices = undefined;
    }
    else {
      if (!isNaN(primaryProfessionalServices)) {
        if (primaryProfessionalServices >= 0) {
          this.primaryProfessionalServices = primaryProfessionalServices;
        }
      }
      else {
        this.primaryProfessionalServices = undefined;
      }
    }
  }



  /**
   * @name SelectSecondaryGovernmentFee
   * @param secondaryGovernmentFee
   * Select government fee
   */
  SelectSecondaryGovernmentFee(secondaryGovernmentFee: number) {
    LoggerHelper.log(secondaryGovernmentFee);
    if (secondaryGovernmentFee.toString().length == 0) {
      this.secondaryGovernmentFee = undefined;
    }
    else {
      if (!isNaN(secondaryGovernmentFee)) {
        if (secondaryGovernmentFee >= 0) {
          this.secondaryGovernmentFee = secondaryGovernmentFee;
        }
      }
      else {
        this.secondaryGovernmentFee = undefined;
      }
    }
  }




  /**
   * @name SelectSecondaryProfessionalServices
   * @param secondaryProfessionalServices
   * Select professional services
   */
  SelectSecondaryProfessionalServices(secondaryProfessionalServices: number) {
    LoggerHelper.log(secondaryProfessionalServices);
    if (secondaryProfessionalServices.toString().length == 0) {
      this.secondaryProfessionalServices = undefined;
    }
    else {
      if (!isNaN(secondaryProfessionalServices)) {
        if (secondaryProfessionalServices >= 0) {
          this.secondaryProfessionalServices = secondaryProfessionalServices;
        }
      }
      else {
        this.secondaryProfessionalServices = undefined;
      }
    }
  }




  /**
   * @name SelectBothProfessionalServices
   * @param bothProfessionalServices
   * Select professional services
   */
  SelectBothProfessionalServices(bothProfessionalServices: number) {
    LoggerHelper.log(bothProfessionalServices);
    if (bothProfessionalServices.toString().length == 0) {
      this.bothProfessionalServices = undefined;
    }
    else {
      if (!isNaN(bothProfessionalServices)) {
        if (bothProfessionalServices >= 0) {
          this.bothProfessionalServices = bothProfessionalServices;
        }
      }
      else {
        this.bothProfessionalServices = undefined;
      }
    }
  }




  /**
   * @name SelectBothGovernmentFee
   * @param bothGovernmentFee
   * Select government fee
   */
  SelectBothGovernmentFee(bothGovernmentFee: number) {
    LoggerHelper.log(bothGovernmentFee);
    if (bothGovernmentFee.toString().length == 0) {
      this.bothGovernmentFee = undefined;
    }
    else {
      if (!isNaN(bothGovernmentFee)) {
        if (bothGovernmentFee >= 0) {
          this.bothGovernmentFee = bothGovernmentFee;
        }
      }
      else {
        this.bothGovernmentFee = undefined;
      }
    }
  }



  GetRequestType() {
    this.sharedService.GetRequestTypes
      .subscribe((data: boolean) => {
        if (data) {
          var requestTypeFee = new RequestTypeFeeRequestModel();
          if (this.forBothFee) {
            if (this.bothGovernmentFee &&
              this.bothGovernmentFee > 0 &&
              this.bothProfessionalServices &&
              this.bothProfessionalServices > 0) {
              requestTypeFee.requestTypeId = this.requestTypeId;

              var requestFee = new RequestFeeRequestModel();
              requestFee.governmentFee = this.bothGovernmentFee;
              requestFee.professionalServices = this.bothProfessionalServices;
              requestFee.isBothClass = true;
              requestFee.isPrimaryClass = false;
              requestFee.isSecondaryClass = false;

              requestTypeFee.fees = [];
              requestTypeFee.fees.push(requestFee);

              this.parent.GetPaidRequestToSaveCountry(requestTypeFee);
            }
            else {
              this.parent.GetPaidRequestToSaveCountry(null);
            }


          }
          else if (!this.forBothFee) {
            if (this.primaryGovernmentFee &&
              this.primaryGovernmentFee > 0 &&
              this.primaryProfessionalServices &&
              this.primaryProfessionalServices > 0 &&
              this.secondaryGovernmentFee &&
              this.secondaryGovernmentFee > 0 &&
              this.secondaryProfessionalServices &&
              this.secondaryProfessionalServices > 0) {
              requestTypeFee.requestTypeId = this.requestTypeId;

              var primaryRequestFee = new RequestFeeRequestModel();
              primaryRequestFee.governmentFee = this.primaryGovernmentFee;
              primaryRequestFee.professionalServices = this.primaryProfessionalServices;
              primaryRequestFee.isBothClass = false;
              primaryRequestFee.isPrimaryClass = true;
              primaryRequestFee.isSecondaryClass = false;

              var secondaryRequestFee = new RequestFeeRequestModel();
              secondaryRequestFee.governmentFee = this.secondaryGovernmentFee;
              secondaryRequestFee.professionalServices = this.secondaryProfessionalServices;
              secondaryRequestFee.isBothClass = false;
              secondaryRequestFee.isPrimaryClass = false;
              secondaryRequestFee.isSecondaryClass = true;


              requestTypeFee.fees = [];
              requestTypeFee.fees.push(primaryRequestFee);
              requestTypeFee.fees.push(secondaryRequestFee);

              this.parent.GetPaidRequestToSaveCountry(requestTypeFee);
            }
            else {

              this.parent.GetPaidRequestToSaveCountry(null);
            }
          }
        }
      });
  }

}
