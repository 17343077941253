export class NavigationKeys {


  /**
   * landing page
   */
  public static LandingPage = 'index';

  /**
   * trademark search
   */
  public static TrademarkSearchPage = 'trademark-search';

  /**
   * sign in page
   */
  public static SignInPage = 'sign-in';


  /**
   * Login page
   */
  public static SignUpPage = 'sign-up';


  /**
   * verify email
   */
  public static VerifyEmailPage = 'verify-email';


  /**
   * comprehensive study
   */
  public static ComprehensiveStudyPage = 'comprehensive-advisory';


  /**
   * registration request
   */
  public static RegistrationRequestPage = 'registration-request';



  /**
   * renew certificate request
   */
  public static RenewCertificateRequestPage = 'renew-certificate';


  /**
   * renew certificate request
   */
  public static TrademarkTransferPage = 'trademark-transfer';


  /**
   * comprehensive study
   */
  public static ComprehensiveStudyCheckoutPage = 'comprehensive-advisory/checkout';


  /**
   * service type process checkout page
   */
  public static ServiceTypeProcessCheckoutPage = 'service-type-process/checkout';



  /**
   * trademark watch
   */
  public static TrademarkWatchPage = 'trademark-watch';


  /**
   * trademark watch info
   */
  public static TrademarkWatchInfoPage = 'trademark-watch-info';

  /**
   * registration request checkout
   */
  public static RegistrationRequestCheckoutPage = 'registration-request/checkout';



  /**
   * registration request checkout
   */
  public static CertificateCheckoutPage = 'certificate-request/checkout';



  /**
   * dashboard
   */
  public static DashboardPage = 'dashboard';



  /**
   * Price
   */
  public static PricePage = 'prices';


  /**
   * About us
   */
  public static AboutUsPage = 'about-us';


  /**
   * About us
   */
  public static ContactUsPage = 'contact-us';


  /**
   * profile
   */
  public static ProfilePage = 'profile';



  /**
   * payment
   */
  public static PaymentPage = 'payment/receipts';


  /**
   * payment
   */
  public static PaymentSearchPage = 'payment/search';



  /**
   * FailPaymentPage
   */
  public static FailPaymentPage = 'payment-failed';


  /**
   * Forgot password
   */
  public static ForgotPasswordPage = 'forgot-password';



  /**
   * User notification
   */
  public static UserNotificationPage = 'user-notification';



  /**
   * ------------------------------------
   * admin
   * ------------------------------------
   */

  public static Admin = 'admin';


  /**
   * dashboard
   */
  public static AdminDashboard = 'dashboard';

  /**
   * comprehensive study
   */
  public static AdminComprehensiveStudy = 'comprehensive-advisory';


  /**
   * registration request
   */
  public static AdminRegistrationRequest = 'registration-request';


  /**
   * registration request
   */
  public static AdminCertificateRequest = 'certificate-request';

  /**
   * discount
   */
  public static AdminDiscount = 'discount';


  /**
   * search configuration
   */
  public static AdminSearchConfiguration = 'search-configuration';


  /**
   * renewal certificate
   */
  public static AdminRenewCertificate = 'renew-certificate';


  /**
   * Trademark transfer
   */
  public static AdminTrademarkTransfer = 'trademark-transfer';



  /**
   * Country
   */
  public static AdminCountry = 'country';


  /**
   * Users
   */
  public static AdminUsers = 'user';


  /**
   * Extra service
   */
  public static AdminExtraService = 'extra-service';


  /**
   * Trademark watch package
   */
  public static AdminWatchPackage = 'watch-package';


  /**
   * Settings
   */
  public static AdminSettings = 'settings';


  /**
   * Admin notification
   */
  public static AdminNotificationPage = 'admin-notification';



  /**
   * Admin email request
   */
  public static AdminEmailRequestPage = 'email-request';



  /**
   * other services
   */
  public static OtherServices = 'other-services';


}
