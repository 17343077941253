export class UtilityHelper {
  //Add delay
  static delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }


  /**
   * @name SortValues
   * @param a
   * @param b
   * @param isAsc
   */
  static SortValues(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
