import { Injectable } from '@angular/core';
import { UsersApplicationService } from '../../services/users/users-application-service.service';

@Injectable({
  providedIn: 'root'
})

export class UserVerificationViewModel {
  constructor(private userService: UsersApplicationService) {
  }




  /**
   * @name VerifyEmail
   * @param verificationToken
   * Verify user email
   */
  public VerifyEmail(verificationToken: string) {
    var data = this.userService.VerifyEmail(verificationToken);
    return data;
  }


  /**
   * @name ResendVerificationEmail
   * @param email
   * Resend verification email
   */
  public ResendVerificationEmail(email: string) {
    var data = this.userService.ResendVerificationEmail(email);
    return data;
  }


}
