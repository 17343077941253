import { Injectable } from '@angular/core';
import { EncryptionDecryptionHelper } from 'src/app/shared/helpers/encryption-decryption';
import { EncryptionDecryptionKeys } from 'src/app/shared/helpers/encryption-decryption-keys.helper';
import { NotificationResponseModel } from '../../models/notification/response/notification-response.model';
import { NotificationApplicationService } from '../../services/notification/notification-application.service';

@Injectable({
  providedIn: 'root'
})

export class NotificationViewModel {
  constructor(private service: NotificationApplicationService) {
  }



  /**
   * @name GetNotificationsByUserId
   * @param userId
   * @returns data
   * Get notifications by user id
   */
  public GetNotificationsByUserId(userId: number) {
    var data = this.service.GetNotificationsByUserId(
      userId
    );

    return data;
  }



  /**
   * @name UpdateNotificationAsSeen
   * @param notificationId
   * @returns data
   * Update notification as seen
   */
  public UpdateNotificationAsSeen(notificationId: number) {
    var data = this.service.UpdateNotificationAsSeen(notificationId);
    return data;
  }



  SaveNotifiationInCache(notifications: NotificationResponseModel[]) {
    notifications.forEach(element => {
      element.userId = EncryptionDecryptionHelper.EncryptStringAES(
        EncryptionDecryptionKeys.EncryptDecryptCommonKey,
        EncryptionDecryptionKeys.IVCommonKey,
        element.userId);
    });

    NotificationResponseModel.ClearInstance();
    NotificationResponseModel.UpdateInstance(notifications);

  }

  RemoveNotificationFromCache(notificationId) {
    var notifications = NotificationResponseModel.Instance;
    var element = notifications.find(f => f.id == notificationId);
    var index = notifications.indexOf(element, 0);
    if (index > -1) {
      notifications.splice(index, 1);
    }


    NotificationResponseModel.ClearInstance();
    NotificationResponseModel.UpdateInstance(notifications);
  }

}
