import { Injectable } from '@angular/core';
import { CachingHelper } from '../../helpers/caching/caching';
import { CachingKeys } from '../../helpers/caching/caching-keys';
import { NotificationResponseModel } from '../../models/notification/response/notification-response.model';
import { UserSessionResponseModel } from '../../models/user-session/response/user-session-response.model';
import { UsersApplicationService } from '../../services/users/users-application-service.service';

@Injectable({
  providedIn: 'root'
})

export class LoginViewModel {
  constructor(private userService: UsersApplicationService) {
  }


  /**
   * @name LoginUser
   * @param body
   * login user
   */
  public LoginUser(body: any) {
    var data = this.userService.LoginUser(body);
    return data;
  }



  /**
   * @name LogoutUser
   * @param userId
   * Logout user
   */
  public LogoutUser(userId: number) {
    var data = this.userService.LogoutUser(userId);
    return data;
  }


  /**
   * @name ForgotPassword
   * @param body
   * forgot password
   */
  public ForgotPassword(body: any) {
    var data = this.userService.ForgotPassword(body);
    return data;
  }



  public ClearSessionData() {
    UserSessionResponseModel.ClearInstance();
    NotificationResponseModel.ClearInstance();
    CachingHelper.removeItemFromCache(CachingKeys.UserSession);
    CachingHelper.removeItemFromCache(CachingKeys.Notification);
  }
}
