import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { NotificationResponseModel } from 'src/app/core/models/notification/response/notification-response.model';
import { NotificationViewModel } from 'src/app/core/viewmodel/notification/notification.viewmodel';
import { EncryptionDecryptionHelper } from '../helpers/encryption-decryption';
import { EncryptionDecryptionKeys } from '../helpers/encryption-decryption-keys.helper';
import { NavigationKeys } from '../helpers/navigation-keys';
import { SnackBar } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class NotificationCardComponent implements OnInit {
  ngOnInit() {
  }

  @Input() notification: NotificationResponseModel;
  @Input() isAdmin: boolean;

  isLoading: boolean = false;

  constructor(
    private viewModel: NotificationViewModel,
    private _snackBar: SnackBar,
    private _router: Router
  ) {

  }


  ClickNotification() {
    this.UpdateNotificationAsSeen(this.notification.id);
  }


  CheckWhereToNavigate() {
    if (this.notification.isRegistrationRequest) {
      if (!this.isAdmin) {
        this.NavigateToRegistrationRequest(this.notification.dutyId);
      }
      else {
        this.NavigationToAdminRegistrationRequest(this.notification.dutyId);
      }
    }
    else if (this.notification.isCertificate) {
      if (!this.isAdmin) {
        this.NavigateToCertificate(this.notification.dutyId);
      }
    }
    else if (this.notification.isComprehensiveStudy) {
      if (!this.isAdmin) {
        this.NavigationToUserComprehensiveStudy(this.notification.dutyId);
      }
      else {
        this.NavigationToAdminComprehensiveStudy(this.notification.dutyId);
      }
    }
    else if (this.notification.isRenewCertificate) {
      if (!this.isAdmin) {
        this.NavigationToUserRenewCertificate(this.notification.dutyId);
      }
      else {
        this.NavigationToAdminRenewCertificate(this.notification.dutyId);
      }
    }
  }



  NavigateToRegistrationRequest(registrationRequestId: number) {
    this._router.navigate([NavigationKeys.DashboardPage + '/registration-request/details'],
      {
        queryParams: {
          registrationRequestId: EncryptionDecryptionHelper.EncryptStringAES(
            EncryptionDecryptionKeys.EncryptDecryptCommonKey,
            EncryptionDecryptionKeys.IVCommonKey, registrationRequestId)
        }
      });
  }

  NavigateToCertificate(certificateId: number) {
    this._router.navigate([NavigationKeys.DashboardPage + '/certificate-request/details'],
      {
        queryParams: {
          certificateId: EncryptionDecryptionHelper.EncryptStringAES(
            EncryptionDecryptionKeys.EncryptDecryptCommonKey,
            EncryptionDecryptionKeys.IVCommonKey, certificateId)
        }
      });
  }

  NavigationToAdminRegistrationRequest(registrationRequestId: number) {
    this._router.navigate([NavigationKeys.Admin + '/' + NavigationKeys.AdminRegistrationRequest + '/manage'], {
      queryParams: {
        registrationRequestId: EncryptionDecryptionHelper.EncryptStringAES(
          EncryptionDecryptionKeys.EncryptDecryptCommonKey,
          EncryptionDecryptionKeys.IVCommonKey, registrationRequestId)
      }
    });
  }



  NavigationToUserComprehensiveStudy(comprehensiveStudyId: number) {
    this._router.navigate([NavigationKeys.DashboardPage + '/comprehensive-advisory/details'], {
      queryParams: {
        comprehensiveStudyId: EncryptionDecryptionHelper.EncryptStringAES(
          EncryptionDecryptionKeys.EncryptDecryptCommonKey,
          EncryptionDecryptionKeys.IVCommonKey, comprehensiveStudyId)
      }
    });
  }


  NavigationToAdminComprehensiveStudy(comprehensiveStudyId: number) {
    this._router.navigate([NavigationKeys.Admin + '/' + NavigationKeys.AdminComprehensiveStudy + '/manage'], {
      queryParams: {
        comprehensiveStudyId: EncryptionDecryptionHelper.EncryptStringAES(
          EncryptionDecryptionKeys.EncryptDecryptCommonKey,
          EncryptionDecryptionKeys.IVCommonKey, comprehensiveStudyId)
      }
    });
  }



  NavigationToUserRenewCertificate(certificateId: number) {
    this._router.navigate([NavigationKeys.DashboardPage + '/renew-certificate/details'], {
      queryParams: {
        certificateId: EncryptionDecryptionHelper.EncryptStringAES(
          EncryptionDecryptionKeys.EncryptDecryptCommonKey,
          EncryptionDecryptionKeys.IVCommonKey, certificateId)
      }
    });
  }



  NavigationToAdminRenewCertificate(certificateId: number) {
    this._router.navigate([NavigationKeys.Admin + '/' + NavigationKeys.AdminRenewCertificate + '/manage'], {
      queryParams: {
        certificateId: EncryptionDecryptionHelper.EncryptStringAES(
          EncryptionDecryptionKeys.EncryptDecryptCommonKey,
          EncryptionDecryptionKeys.IVCommonKey, certificateId)
      }
    });
  }



  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  START API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------


  /**
   * @name UpdateNotificationAsSeen
   * @param notificationId
   * @returns data
   * Update notification as seen
   */
  async UpdateNotificationAsSeen(notificationId: number) {
    this.isLoading = true;
    await this.viewModel
      .UpdateNotificationAsSeen(notificationId)
      .toPromise()
      .then((result) => {
        LoggerHelper.log(
          'New Data inside UpdateNotificationAsSeen : ' + JSON.stringify(result)
        );
        if (result.isSuccessfullyDone) {
          this.viewModel.RemoveNotificationFromCache(notificationId);
          this.CheckWhereToNavigate();
        }
      })
      .catch((error) => {
        LoggerHelper.log('Error :: ' + error);
        this._snackBar.ShowWarning('There is an issue with you internet connection');

      })
      .finally(() => {
        this.isLoading = false;
      });
  }


  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  End API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------

}
