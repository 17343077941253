import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalApiVariables {

  //DEV
  public readonly ALTarekBaseApiServer =
    'http://127.0.0.1:8000/api';



  // public readonly ALTarekBaseApiServer =
  //   'https://worldtek.co/altarek/api';


  //Hesabe
  public readonly HesabeBasedApi =
    'https://sandbox.hesabe.com';

  // user

  public readonly LoginUrl = this.ALTarekBaseApiServer + '/login'

  public readonly LogoutUrl = this.ALTarekBaseApiServer + '/users/{0}/logout'

  public readonly SignUpUrl = this.ALTarekBaseApiServer + '/users'

  public readonly VerifyEmail = this.ALTarekBaseApiServer + '/users/verify/{0}'

  public readonly ResendEmailVerification = this.ALTarekBaseApiServer + '/users/{0}/resendVerificationMail'

  public readonly ApiGetAllUsers = this.ALTarekBaseApiServer + '/users?page={0}&perPage={1}'

  public readonly ApiUpdateUser = this.ALTarekBaseApiServer + '/users/{0}'

  public readonly ApiGetUserById = this.ALTarekBaseApiServer + '/users/{0}'


  public readonly RecoveryPasswordUrl = this.ALTarekBaseApiServer + '/forgotPassword'
  // end user


  // country
  public readonly ApiGetAllCountries = this.ALTarekBaseApiServer + '/countries';
  public readonly ApiCreateCountry = this.ALTarekBaseApiServer + '/countries';
  public readonly ApiGetCountryById = this.ALTarekBaseApiServer + '/countries/{0}';
  public readonly ApiUpdateCountryById = this.ALTarekBaseApiServer + '/countries/{0}';
  public readonly ApiDeleteCountryById = this.ALTarekBaseApiServer + '/countries/{0}';

  //classes
  public readonly ApiGetAllClasses = this.ALTarekBaseApiServer + '/classes';


  //comprehensive study
  public readonly ApiCreateComprehensiveStudy = this.ALTarekBaseApiServer + '/comprehensiveStudies';
  public readonly ApiGetUnpaidComprehensiveStudy = this.ALTarekBaseApiServer + '/serviceTypes/{0}/comprehensiveStudies/unpaid';
  public readonly ApiGetAvailableComprehensiveStudies = this.ALTarekBaseApiServer + '/comprehensiveStudies?newStudies={0}&awaitingStudies={1}&pendingStudies={2}&inProgressStudies={3}&doneStudies={4}';
  public readonly ApiGetComprehensiveStudyById = this.ALTarekBaseApiServer + '/comprehensiveStudies/{0}';
  public readonly ApiGetComprehensiveStudyByUserId = this.ALTarekBaseApiServer + '/users/{0}/comprehensiveStudies';
  public readonly ApiGetComprehensiveStudyWithIssues = this.ALTarekBaseApiServer + '/comprehensiveStudies/{0}/comprehensiveStudyIssues';
  public readonly ApiUpdateComprehensiveStudyToInProgress = this.ALTarekBaseApiServer + '/comprehensiveStudies/{0}/comprehensiveStudies/inProgress()';
  public readonly ApiUpdateComprehensiveStudyToReadyToRegister = this.ALTarekBaseApiServer + '/comprehensiveStudies/{0}/comprehensiveStudies/readyToRegister()';
  public readonly ApiGetComprehensiveStudyReport = this.ALTarekBaseApiServer + '/report/comprehensiveStudies';



  //registration request
  public readonly ApiRegisterComprehensiveStudy = this.ALTarekBaseApiServer + '/comprehensiveStudies/{0}/registrationRequests';
  public readonly ApiCreateRegistrationRequest = this.ALTarekBaseApiServer + '/registrationRequests';
  public readonly ApiGetUnpaidRegistrationRequest = this.ALTarekBaseApiServer + '/serviceTypes/{0}/registrationRequests/unpaid';
  public readonly ApiGetRegistrationRequestByUserId = this.ALTarekBaseApiServer + '/users/{0}/registrationRequests';
  public readonly ApiGetRegistrationRequestWithIssues = this.ALTarekBaseApiServer + '/registrationRequests/{0}/registrationRequestIssues';
  public readonly ApiGetAvailableRegistrationRequests = this.ALTarekBaseApiServer + '/registrationRequests?newRequests={0}&awaitingRequests={1}&pendingRequests={2}&inProgressRequests={3}&doneRequests={4}';
  public readonly ApiGetRegistrationRequestById = this.ALTarekBaseApiServer + '/registrationRequests/{0}';
  public readonly ApiUpdateRegistrationRequestToInProgress = this.ALTarekBaseApiServer + '/registrationRequests/{0}/registrationRequests/inProgress()';
  public readonly ApiGetRegistrationRequestReport = this.ALTarekBaseApiServer + '/report/registrationRequests';
  public readonly ApiUpdateRegistrationRequestFilingDate = this.ALTarekBaseApiServer + '/registrationRequests/{0}/addFilingDate()';
  public readonly ApiSetPublicationDuration = this.ALTarekBaseApiServer + '/registrationRequests/{0}/setPublicationDuration()';
  public readonly ApiSetObjectionDuration = this.ALTarekBaseApiServer + '/registrationRequests/{0}/setObjectionDuration()';
  public readonly ApiSetIssueCertificateDuration = this.ALTarekBaseApiServer + '/registrationRequests/{0}/setIssueCertificateDuration()';
  public readonly ApiSetAppealExaminationDuration = this.ALTarekBaseApiServer + '/registrationRequests/{0}/setAppealExaminationDuration()';
  public readonly ApiSetUnderAppeal = this.ALTarekBaseApiServer + '/registrationRequests/{0}/setUnderAppeal()';
  public readonly ApiSetUnderCounterObjection = this.ALTarekBaseApiServer + '/registrationRequests/{0}/setUnderCounterObjection()';
  public readonly ApiSetCourt = this.ALTarekBaseApiServer + '/registrationRequests/{0}/setCourt()';
  public readonly ApiSetCounterObjection = this.ALTarekBaseApiServer + '/registrationRequests/{0}/setCounterObjection()';
  public readonly ApiDeleteRegistrationRequestById = this.ALTarekBaseApiServer + '/registrationRequests/{0}';
  public readonly ApiUpdateRegistrationRequestToReadyForCertificate = this.ALTarekBaseApiServer + '/serviceTypes/{0}/readyForCertificate()';
  public readonly ApiCreateCertificateFromRegistrationRequest = this.ALTarekBaseApiServer + '/registrationRequests/{0}/certificates';


  // certificate request
  public readonly ApiCreateRenewalCertificate = this.ALTarekBaseApiServer + '/certificates';
  public readonly ApiGetAvailableCertificateRequests = this.ALTarekBaseApiServer + '/certificates?newCertificate={0}&awaitingCertificate={1}&pendingCertificate={2}&inProgressCertificate={3}&doneCertificate={4}';
  public readonly ApiGetCertificateById = this.ALTarekBaseApiServer + '/certificates/{0}';
  public readonly ApiUpdateFilingDate = this.ALTarekBaseApiServer + '/certificates/{0}/certificates/addFilingDate()';
  public readonly ApiUpdateCertificateToInProgress = this.ALTarekBaseApiServer + '/certificates/{0}/certificates/inProgress()';
  public readonly ApiUpdateRegistrationNumber = this.ALTarekBaseApiServer + '/certificates/{0}/certificates/updateRegistrationNumber()';
  public readonly ApiUploadCertification = this.ALTarekBaseApiServer + '/serviceTypes/{0}/certifications';
  public readonly ApiUpdateCertificateToDone = this.ALTarekBaseApiServer + '/certificates/{0}/certificates/done()';
  public readonly ApiGetCertificatesByUserId = this.ALTarekBaseApiServer + '/users/{0}/certificates';
  public readonly ApiCreateCertificateAfterRegistration = this.ALTarekBaseApiServer + '/registrationRequests/{0}/certificates';
  public readonly ApiGetUnpaidCertificate = this.ALTarekBaseApiServer + '/serviceTypes/{0}/certificates/unpaid';
  public readonly ApiGetCertificatesReport = this.ALTarekBaseApiServer + '/report/certificates';
  public readonly ApiGetRenewCertificatesByUserId = this.ALTarekBaseApiServer + '/users/{0}/renewCertificates';
  public readonly ApiRenewCertificate = this.ALTarekBaseApiServer + '/certificates/{0}/renewCertificate()';
  public readonly ApiGetRenewCertificateWithFee = this.ALTarekBaseApiServer + '/certificates/{0}/renewCertificatesWithFee()';
  public readonly ApiUpdateRenewCertificateToDone = this.ALTarekBaseApiServer + '/certificates/{0}/renewCertificates/done()';
  public readonly ApiGetRenewCertificatesReport = this.ALTarekBaseApiServer + '/report/renewCertificates';


  //Trademark transfer
  public readonly ApiCreateTrademarkTransfer = this.ALTarekBaseApiServer + '/trademarkTransfers';
  public readonly ApiGetAllAvailableTrademarkTransfer = this.ALTarekBaseApiServer + '/trademarkTransfers?newTransfer={0}&awaitingTransfer={1}&pendingTransfer={2}&inProgressTransfer={3}&doneTransfer={4}';
  public readonly ApiGetTrademarkTransferById = this.ALTarekBaseApiServer + '/trademarkTransfers/{0}';
  public readonly ApiGetTrademarkTransferByUserId = this.ALTarekBaseApiServer + '/users/{0}/trademarkTransfers';
  public readonly ApiGetTrademarkTransferWithFee = this.ALTarekBaseApiServer + '/trademarkTransfers/{0}/awaitingTrademarkTransfer()';
  public readonly ApiUpdateTrademarkTransferToInProgress = this.ALTarekBaseApiServer + '/trademarkTransfers/{0}/trademarkTransfers/inProgress()';
  public readonly ApiUpdateTrademarkTransferToInDone = this.ALTarekBaseApiServer + '/trademarkTransfers/{0}/trademarkTransfers/done()';
  public readonly ApiGetTrademarkTransferReport = this.ALTarekBaseApiServer + '/report/trademarkTransfers';

  // trademark search
  public readonly ApiSearchTrademark = this.ALTarekBaseApiServer + '/trademark/searchs';

  //trademark watch
  public readonly ApiGetPackages = this.ALTarekBaseApiServer + '/packages';
  public readonly ApiCreatePackage = this.ALTarekBaseApiServer + '/packages';
  public readonly ApiUpdatePackage = this.ALTarekBaseApiServer + '/packages/{0}';
  public readonly ApiDeletePackage = this.ALTarekBaseApiServer + '/packages/{0}';
  public readonly ApiGetPackage = this.ALTarekBaseApiServer + '/packages/{0}';
  public readonly ApiCreateTrademarkWatch = this.ALTarekBaseApiServer + '/trademarkWatches';
  public readonly ApiGetTrademarkWatchByUserId = this.ALTarekBaseApiServer + '/users/{0}/trademarkWatches';

  // renew certificate request
  public readonly ApiGetAvailableRenewCertificateRequests = this.ALTarekBaseApiServer + '/renewCertificates?newCertificate={0}&awaitingCertificate={1}&pendingCertificate={2}&inProgressCertificate={3}&doneCertificate={4}';


  // service custom fee
  public readonly ApiCreateServiceCustomFee = this.ALTarekBaseApiServer + '/serviceTypes/{0}/serviceCustomFees';



  //logos
  public readonly ApiUploadLogos = this.ALTarekBaseApiServer + '/logos';

  //files
  public readonly ApiUploadFiles = this.ALTarekBaseApiServer + '/serviceTypes/{0}/files';


  //logos
  public readonly ApiUploadReports = this.ALTarekBaseApiServer + '/serviceTypes/{0}/reports';


  //receipts
  public readonly ApiCreateReceipts = this.ALTarekBaseApiServer + '/receipts';
  public readonly ApiPayReceipts = this.ALTarekBaseApiServer + '/receipts/pay()';
  public readonly ApiPayFreeReceipts = this.ALTarekBaseApiServer + '/receipts/payFree()';
  public readonly ApiPayTempReceipts = this.ALTarekBaseApiServer + '/receipts/tempPay()';

  //extra service
  public readonly ApiGetAllAvailableExtraServices = this.ALTarekBaseApiServer + '/extraServices';
  public readonly ApiCreateExtraServices = this.ALTarekBaseApiServer + '/extraServices';
  public readonly ApiDeleteExtraService = this.ALTarekBaseApiServer + '/extraServices/{0}';
  public readonly ApiGetExtraServiceById = this.ALTarekBaseApiServer + '/extraServices/{0}';
  public readonly ApiUpdateExtraService = this.ALTarekBaseApiServer + '/extraServices/{0}';

  //promo code
  public readonly ApiGetPromoCodeDiscount = this.ALTarekBaseApiServer + '/promoCodes/discount';
  public readonly ApiCreatePromoCode = this.ALTarekBaseApiServer + '/users/{0}/promoCodes';

  //issue
  public readonly ApiCreateIssue = this.ALTarekBaseApiServer + '/serviceTypes/{0}/issues';
  public readonly ApiCheckOutIssue = this.ALTarekBaseApiServer + '/users/{0}/checkOutIssue';


  //request types
  public readonly ApiGetRequestTypes = this.ALTarekBaseApiServer + '/requestTypes';
  public readonly ApiGetPaidRequestTypes = this.ALTarekBaseApiServer + '/paidRequestTypes';

  //discount
  public readonly ApiCreateDiscountByCountry = this.ALTarekBaseApiServer + '/discounts/country';


  //prices
  public readonly ApiGetCountiresPrices = this.ALTarekBaseApiServer + '/countries/prices/all()';
  public readonly ApiGetCountiresDetails = this.ALTarekBaseApiServer + '/countries/{0}/prices';

  //search request fee
  public readonly ApiGetSearchRequestFee = this.ALTarekBaseApiServer + '/searchRequestFees';
  public readonly ApiCreateSearchRequestFee = this.ALTarekBaseApiServer + '/searchRequestFees';

  //search access control
  public readonly ApiGetSearchAccessControl = this.ALTarekBaseApiServer + '/searchAccessControls';
  public readonly ApiCreateOrUpdateSearchAccessControl = this.ALTarekBaseApiServer + '/searchAccessControls';


  //user search request
  public readonly ApiGetUserSearchRequestByUserId = this.ALTarekBaseApiServer + '/userSearchRequests/{0}';
  public readonly ApiCreateOrUpdateUserSearchRequest = this.ALTarekBaseApiServer + '/userSearchRequests';


  //paytabs
  public readonly ApiValidateSecretKey = 'https://www.paytabs.com/apiv2/validate_secret_key';
  public readonly ApiCreatePayPage = 'https://www.paytabs.com/apiv2/create_pay_page';


  //contact us
  public readonly ApiSendEmailForContactUs = this.ALTarekBaseApiServer + '/contactus';


  //hesabe
  public readonly ApiHesabeCheckout = this.HesabeBasedApi + '/checkout';

  //subclass
  public readonly ApiGetSubClassByClassId = this.ALTarekBaseApiServer + '/classes/{0}/subClasses';



  //serviceType process additional info
  public readonly ApiCreateServiceTypeProcessAdditionalInfo = this.ALTarekBaseApiServer + '/serviceTypeProcesses/{0}/serviceTypeProcessAdditionalInfos';


  //serviceType process
  public readonly ApiGetUnpaidServiceTypeProcess = this.ALTarekBaseApiServer + '/users/{0}/serviceTypeProcesses/{1}/unpaidServiceTypeProcesses';


  //notification
  public readonly ApiGetNotifications = this.ALTarekBaseApiServer + '/users/{0}/notifications';
  public readonly ApiUpdateNotificationAsSeen = this.ALTarekBaseApiServer + '/notifications/{0}/isSeen()';

  //admin request
  public readonly ApiCreateRequestForClient = this.ALTarekBaseApiServer + '/adminRequests';


}
