import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-calls/global-variables';
import { HttpCall } from '../../http/http-calls/http-calls';

@Injectable({
  providedIn: 'root'
})
export class CountriesApplicationService {

  constructor(
    private globalApiVariables: GlobalApiVariables,
    private http: HttpCall) {
  }



  /**
   * @name GetAllCountries
   * Get all countries
   */
  public GetAllCountries() {
    var data = this.http.GetAsync(
      true,
      this.globalApiVariables.ApiGetAllCountries
    );

    return data;
  }


  /**
   * @name CreateCountry
   * @param body
   * Create country
   */
  public CreateCountry(body: any) {
    var data = this.http.PostAsync(
      true,
      body,
      this.globalApiVariables.ApiCreateCountry
    );
    return data;
  }


  /**
   * @name UpdateCountry
   * @param countryId
   * @param body
   * Update country
   */
  public UpdateCountry(countryId: number, body: any) {
    var data = this.http.PutAsync(
      true,
      body,
      this.globalApiVariables.ApiUpdateCountryById,
      countryId
    );

    return data;
  }


  /**
   * @name GetCountryDetails
   * @param countryId
   * Get country details
   */
  public GetCountryDetails(countryId: number) {
    var data = this.http.GetAsync(
      true,
      this.globalApiVariables.ApiGetCountryById,
      countryId
    );

    return data;
  }


  /**
   * @name DeleteCountry
   * @param countryId
   * @returns data
   * Delete country by id
   */
  public DeleteCountry(countryId: number) {
    var data = this.http.DeleteAsync(
      true,
      this.globalApiVariables.ApiDeleteCountryById,
      countryId
    );

    return data;
  }
}
