export class HttpExceptionKeys {
  public static UnAuthorized = 'Unauthorized';
  public static BadRequest = 'Bad Request';
  public static NotFound = 'Not Found';
  public static Conflict = 'Conflict';
  public static AlreadyExists = 'Already Exists';
  public static ServiceUnavailable = 'Service Unavailable';
  public static NoInternetConnection = 'No Internet Connection';
  public static MethodNotFound = 'Method not found';
  public static IncorrectRequestData = 'Incorrect request data'
}
