<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 email-verification-dialog">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
    <span class="title">Thank you for creating an account with AL Marsa</span>
  </div>

  <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-3">
    <img src="../../../../assets/app/icons/email.jpg" class="email-icon" alt="email">
  </div> -->

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-3">
    <span class="description">Please confirm your email address</span>
  </div>

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-2">
    <span class="description">We sent the verification email to : {{data.email}}</span>
  </div>

  <mat-dialog-actions align="end">
    <!-- <button mat-button (click)="ResendVerificationEmail()">Resend</button> -->
    <button mat-button mat-dialog-close>Ok</button>
  </mat-dialog-actions>
</div>
