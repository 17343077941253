import { Injectable } from '@angular/core';
import { IssuesApplicationService } from '../../services/issues/issues-application-service.service';

@Injectable({
  providedIn: 'root'
})

export class IssueViewModel {
  constructor(
    private service: IssuesApplicationService) {
  }




  /**
   * @name CreateIssue
   * @param serviceTypeId
   * @param body
   * Create an issue for a service type Id
   */
  public CreateIssue(serviceTypeId: number, body: any) {
    var data = this.service.CreateIssue(serviceTypeId, body);
    return data;
  }

}
