<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 admin-trademark-information">


  <!-- full name -->
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Full Name</h4>
    </div>

    <div class="col-9 p-0">
      <h4 class="info-value">{{ firstname }} {{ lastname }}</h4>
    </div>
  </div>

  <!-- email -->
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Email</h4>
    </div>

    <div class="col-9 p-0">
      <h4 class="info-value">{{ email }}</h4>
    </div>
  </div>


  <!-- date -->
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Date</h4>
    </div>

    <div class="col-9 p-0">
      <h4 class="info-value">{{ date }}</h4>
    </div>
  </div>


  <!-- filing date -->
  <div *ngIf="filingDate && filingDate.length > 0"
    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Filing Date</h4>
    </div>

    <div class="col-9 p-0">
      <h4 class="info-value">{{ filingDate }}</h4>
    </div>
  </div>

  <!-- expiration date -->
  <div *ngIf="expirationDate && expirationDate.length > 0"
    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Expiration Date</h4>
    </div>

    <div class="col-9 p-0">
      <h4 class="info-value">{{ expirationDate }}</h4>
    </div>
  </div>

  <!-- registration date -->
  <!-- <div *ngIf="registrationDate && registrationDate.length>0"
    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Registration Date</h4>
    </div>

    <div class="col-9 p-0">
      <h4 class="info-value">{{ registrationDate }}</h4>
    </div>
  </div> -->


  <!-- registration number -->
  <div *ngIf="registrationNumber && registrationNumber.length > 0"
    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Registration number</h4>
    </div>

    <div class="col-9 p-0">
      <h4 class="info-value">{{ registrationNumber }}</h4>
    </div>
  </div>


  <!-- mark -->
  <div *ngIf="mark && mark.length>0" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Mark</h4>
    </div>

    <div class="col-9 p-0">
      <h4 class="info-value">{{ mark }}</h4>
    </div>
  </div>


  <!-- logo -->
  <div *ngIf="logo && logo.length>0" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Logo</h4>
    </div>

    <div class="col-9 p-0">
      <img src="{{logo}}" alt="logo" class="logo">
    </div>
  </div>


  <!-- mark -->
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Description</h4>
    </div>

    <div class="col-9 p-0">
      <h4 [innerHTML]="description" class="info-value"></h4>
    </div>
  </div>



  <!-- country -->
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 mt-3 p-0">
    <div class="col-3 p-0">
      <h4 class="key">Country</h4>
    </div>

    <div class="col-9 p-0">
      <h4 class="info-value">Primary : {{primaryCountry.name}}</h4>
      <h4 *ngIf="secondaryCountries && secondaryCountries.length > 0" class="info-value row">Secondary : &nbsp;
        <div *ngFor="let country of secondaryCountries">
          <span *ngIf="country.id == secondaryCountries[0].id">
            {{country.name}}
          </span>
          <span *ngIf="country.id != secondaryCountries[0].id">
            , {{country.name}}
          </span>
        </div>
      </h4>
    </div>
  </div>

  <!-- classes -->
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 mt-3 p-0">
    <div class="col-3 p-0">
      <h4 class="key">Classes</h4>
    </div>

    <div class="col-9 p-0">
      <!-- <h4 class="info-value">Primary : {{primaryClass.name}}</h4>
      <h4 *ngIf="secondaryClasses && secondaryClasses.length > 0" class="info-value row">Secondary : &nbsp;
        <div *ngFor="let class of secondaryClasses">
          <span *ngIf="class.id == secondaryClasses[0].id">
            {{class.name}}
          </span>
          <span *ngIf="class.id != secondaryClasses[0].id">
            , {{class.name}}
          </span>
        </div>
      </h4> -->
      <div *ngFor="let class of classes">
        <h4 *ngIf="class.isPrimary" class="info-value"><b>Primary class :</b> </h4>
        <h4 *ngIf="class.isPrimary" class="info-value">{{class.name}}</h4>
        <h4 *ngIf="!class.isPrimary"><b>Secondary class :</b></h4>
        <h4 *ngIf="!class.isPrimary" class="info-value">{{class.name}}</h4>
        <h4 class="info-value"><b>Sub class :</b></h4>
        <h4 *ngFor="let subClass of class.subClasses">- {{subClass.name}}</h4>
      </div>
    </div>
  </div>

  <!-- files -->
  <div *ngIf="files && files.length > 0" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Files</h4>
    </div>

    <div class="col-9 p-0">
      <div class="action-button">
        <button (click)="OpenFilesDialog()" mat-button>
          <span> Preview files </span>
        </button>
      </div>
    </div>
  </div>


  <!-- certification -->
  <div *ngIf="certification && certification.length > 0"
    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Certification</h4>
    </div>

    <div class="col-9 p-0">
      <div class="action-button">
        <button (click)="OpenCertification()" mat-button>
          <span> Preview certification </span>
        </button>
      </div>
    </div>
  </div>


  <!-- files -->
  <div *ngIf="report && report.length > 0" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Report</h4>
    </div>

    <div class="col-9 p-0">
      <div class="action-button">
        <button (click)="OpenReport()" mat-button>
          <span> Preview report </span>
        </button>
      </div>
    </div>
  </div>


  <ng-container *ngIf="trademarkTransferOwner">
    <!-- trademark transfer -->
    <div *ngIf="trademarkTransferOwner" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-5">
      <div class="col-12 p-0">
        <h4 class="key">Trademark new owner</h4>
      </div>

      <!-- <div class="col-9 p-0">
        <div class="action-button">
        <button (click)="OpenCertification()" mat-button>
          <span> Preview certification </span>
        </button>
      </div>
      </div> -->
    </div>


    <!-- full legal name -->
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
      <div class="col-3 p-0">
        <h4 class="key">Full legal name</h4>
      </div>

      <div class="col-9 p-0">
        <h4 class="info-value">{{ trademarkTransferOwner.fullname }}</h4>
      </div>
    </div>


    <!-- address -->
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
      <div class="col-3 p-0">
        <h4 class="key">Address</h4>
      </div>

      <div class="col-9 p-0">
        <h4 class="info-value">{{ trademarkTransferOwner.address }}</h4>
      </div>
    </div>


    <!-- City -->
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
      <div class="col-3 p-0">
        <h4 class="key">City</h4>
      </div>

      <div class="col-9 p-0">
        <h4 class="info-value">{{ trademarkTransferOwner.city }}</h4>
      </div>
    </div>


    <!-- State -->
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
      <div class="col-3 p-0">
        <h4 class="key">State</h4>
      </div>

      <div class="col-9 p-0">
        <h4 class="info-value">{{ trademarkTransferOwner.state }}</h4>
      </div>
    </div>


    <!-- Country -->
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
      <div class="col-3 p-0">
        <h4 class="key">Country</h4>
      </div>

      <div class="col-9 p-0">
        <h4 class="info-value">{{ trademarkTransferOwner.country }}</h4>
      </div>
    </div>


    <!-- Postal code -->
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
      <div class="col-3 p-0">
        <h4 class="key">Postal code</h4>
      </div>

      <div class="col-9 p-0">
        <h4 class="info-value">{{ trademarkTransferOwner.postalCode }}</h4>
      </div>
    </div>


    <!-- Phone call -->
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
      <div class="col-3 p-0">
        <h4 class="key">Phone number</h4>
      </div>

      <div class="col-9 p-0">
        <h4 class="info-value">{{ trademarkTransferOwner.internationalPhoneCode }} {{ trademarkTransferOwner.phone }}
        </h4>
      </div>
    </div>

  </ng-container>

</div>
