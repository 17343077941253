import { CachingHelper } from 'src/app/core/helpers/caching/caching';
import { CachingKeys } from 'src/app/core/helpers/caching/caching-keys';
import { SerializationHelper } from 'src/app/core/helpers/serialization/serialization-helper';

export class NotificationResponseModel {
  id: number;
  userId: number;
  serviceTypeId: number;
  dutyId: number;
  name: string;
  logo: string;
  message: string;
  description: string;
  date: Date;
  isSeen: boolean;
  isComprehensiveStudy: boolean;
  isRegistrationRequest: boolean;
  isCertificate: boolean;
  isRenewCertificate: boolean;
  isTrademarkTransfer: boolean;
  isTrademarkWatch: boolean;



  public static _instance: NotificationResponseModel[];



  public static get Instance() {
    if (this._instance == null) {
      var notification: NotificationResponseModel[] = [];
      this._instance = SerializationHelper.toInstance(
        notification,
        CachingHelper.getItemFromCache(CachingKeys.Notification)
      );
      return this._instance;
    }
    return this._instance;
  }


  public static ClearInstance() {
    this._instance = null;
  }


  public static UpdateInstance(data: NotificationResponseModel[]) {
    CachingHelper.saveItemIntoCache(
      CachingKeys.Notification,
      JSON.stringify(data)
    );
  }

}
