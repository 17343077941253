import { Injectable } from '@angular/core';
import { UsersApplicationService } from '../../services/users/users-application-service.service';

@Injectable({
  providedIn: 'root'
})

export class UsersViewModel {
  constructor(
    private service: UsersApplicationService) {
  }


  /**
   * Get all users
   */
  public GetAllUsers(page: number, perPage: number) {
    var data = this.service.GetAllUsers(page, perPage);
    return data;
  }



  /**
   * @name GetUserById
   * @param userId
   * Get user by id
   */
  public GetUserById(userId: number) {
    var data = this.service.GetUserById(userId);
    return data;
  }

}
