import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { GlobalVaribales } from '../helpers/global-variables';
import { SnackBar } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DropZoneComponent implements OnInit {
  ngOnInit() {
  }

  documents: File[] = [];

  @Input() name: string;

  @Output() addDocument = new EventEmitter();
  @Output() removeDocument = new EventEmitter();

  maxImageFileSize: number;

  constructor(private _snackBar: SnackBar) {
    this.maxImageFileSize = GlobalVaribales.LogoImageMaxSize;
  }


  OnSelectDocument(event) {
    if (event.rejectedFiles) {
      if (event.rejectedFiles.length > 0) {
        if (event.rejectedFiles[0].reason == 'size') {
          this._snackBar.ShowWarning('Cannot support this size of images. The maximum size is 2MB');
        }
        else if (event.rejectedFiles[0].reason == 'type') {
          this._snackBar.ShowWarning('Please provide us a document type');
        }
      }
      this.documents.push(...event.addedFiles);
      this.addDocument.emit(event);
    }

    LoggerHelper.log(this.documents);

  }

  OnRemoveDocument(event) {
    this.documents.splice(this.documents.indexOf(event), 1);
    this.removeDocument.emit(event);
  }

}
