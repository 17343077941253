import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../auth/auth-guard/auth-guard.service';
import { NavigationKeys } from '../shared/helpers/navigation-keys';
import { InitializeRouteComponent } from './initialize-route/initialize-route.component';
import { PagesComponent } from './pages.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        component: InitializeRouteComponent,
      },
      {
        path: NavigationKeys.LandingPage,
        loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
      },
      {
        path: NavigationKeys.TrademarkSearchPage,
        loadChildren: () => import('./trademark-search/trademark-search.module').then(m => m.TrademarkSearchModule),
      },
      {
        path: NavigationKeys.ComprehensiveStudyPage,
        loadChildren: () => import('./service-type-request-form/service-type-request-form.module').then(m => m.ServiceTypeRequestFormModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.RegistrationRequestPage,
        loadChildren: () => import('./service-type-request-form/service-type-request-form.module').then(m => m.ServiceTypeRequestFormModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.RenewCertificateRequestPage,
        loadChildren: () => import('./service-type-request-form/service-type-request-form.module').then(m => m.ServiceTypeRequestFormModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.TrademarkTransferPage,
        loadChildren: () => import('./service-type-request-form/service-type-request-form.module').then(m => m.ServiceTypeRequestFormModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.TrademarkWatchPage,
        loadChildren: () => import('./trademark-watch/trademark-watch.module').then(m => m.TrademarkWatchFormModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.TrademarkWatchInfoPage,
        loadChildren: () => import('./trademark-watch-info/trademark-watch-info.module').then(m => m.TrademarkWatchInfoPageModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.ComprehensiveStudyCheckoutPage,
        loadChildren: () => import('./service-type-checkout/service-type-checkout.module').then(m => m.ServiceTypeCheckoutModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.ServiceTypeProcessCheckoutPage,
        loadChildren: () => import('./service-type-process-checkout/service-type-process-checkout.module').then(m => m.ServiceTypeProcessCheckoutModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.RegistrationRequestCheckoutPage,
        loadChildren: () => import('./service-type-checkout/service-type-checkout.module').then(m => m.ServiceTypeCheckoutModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.CertificateCheckoutPage,
        loadChildren: () => import('./service-type-checkout/service-type-checkout.module').then(m => m.ServiceTypeCheckoutModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.DashboardPage,
        loadChildren: () => import('./user-dashboard/user-dashboard.module').then(m => m.UserDashboardModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.ProfilePage,
        loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.PaymentPage,
        loadChildren: () => import('./pay-receipt/pay-receipt.module').then(m => m.PayReceiptModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.PaymentSearchPage,
        loadChildren: () => import('./pay-receipt/pay-receipt.module').then(m => m.PayReceiptModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.FailPaymentPage,
        loadChildren: () => import('./fail-payment/fail-payment.module').then(m => m.FailPaymentModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.UserNotificationPage,
        loadChildren: () => import('./user-notification/user-notification.module').then(m => m.UserNotificationPageModule),
        canActivate: [AuthGuardService],
      },
      {
        path: NavigationKeys.ForgotPasswordPage,
        loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
      },
      {
        path: NavigationKeys.PricePage,
        loadChildren: () => import('./prices/price.module').then(m => m.PriceModule)
      },
      {
        path: NavigationKeys.AboutUsPage,
        loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsPageModule)
      },
      {
        path: NavigationKeys.ContactUsPage,
        loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsPageModule)
      },
      {
        path: NavigationKeys.SignInPage,
        loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule),
      },
      {
        path: NavigationKeys.SignUpPage,
        loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpModule),
      },
      {
        path: NavigationKeys.VerifyEmailPage,
        loadChildren: () => import('./user-verification/user-verification.module').then(m => m.UserVerificationModule),
      },
      {
        path: NavigationKeys.OtherServices,
        loadChildren: () => import('./other-services/other-services.module').then(m => m.OtherServicesModule),
      }
    ],
  },
  { path: 'admin', loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
