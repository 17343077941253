import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'informative-dialog',
  templateUrl: './informative-dialog.component.html',
  styleUrls: ['./informative-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class InformativeDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

}
