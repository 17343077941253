import { Injectable } from '@angular/core';
import { SubClassApplicationService } from '../../services/subclass/subclass-application.service';

@Injectable({
  providedIn: 'root'
})

export class SubClassViewModel {
  constructor(
    private service: SubClassApplicationService) {
  }


  public GetSubClassesByClassId(classId: number) {
    var data = this.service.GetSubClassesByClassId(classId);
    return data;
  }

}
