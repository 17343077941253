import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { HttpExceptionKeys } from 'src/app/core/http/http-calls/http-exception-keys';
import { PredefinedExtraService } from 'src/app/core/models/extra-service/predefined-extra-service';
import { ExtraServiceResponseModel } from 'src/app/core/models/extra-service/response/extra-service-model';
import { ExtraServiceViewModel } from 'src/app/core/viewmodel/extra-service/extra-service.viewmodel';
import { GlobalVaribales } from '../../helpers/global-variables';
import { UtilityHelper } from '../../helpers/utility';
import { SnackBar } from '../../snack-bar/snack-bar.component';

@Component({
  selector: 'predefined-service-dialog',
  templateUrl: './predefined-service-dialog.component.html',
  styleUrls: ['./predefined-service-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class PredefinedServiceDialog {


  isLoading: boolean = true;

  displayedColumns: string[] = [
    'select',
    'description',
    'quantity',
    'price',
    'discount'
  ];


  extraServices: ExtraServiceResponseModel[];

  dataSource = new MatTableDataSource<ExtraServiceResponseModel>();
  selection = new SelectionModel<ExtraServiceResponseModel>(true, []);

  constructor(
    public dialogRef: MatDialogRef<PredefinedServiceDialog>,
    private viewModel: ExtraServiceViewModel,
    private _snackBar: SnackBar) {
    this.GetAllAvailableExtraServices();
  }



  async LoadData() {
    await UtilityHelper.delay(
      GlobalVaribales.ShortDelayToLoadLazyDataBetweenComponents
    ); //delay the data reading, until the data has been set from the previous page using lazy loading

    this.dataSource = new MatTableDataSource<ExtraServiceResponseModel>(
      this.extraServices
    );
  }


  AddExtraFee() {
    var isValid = true;
    for (var i = 0; i < this.selection.selected.length; i++) {
      if (this.selection.selected[i].quantity == undefined || this.selection.selected[i].quantity.toString().length == 0) {
        this._snackBar.ShowWarning('Please define a quantity for ' + this.selection.selected[i].description);
        isValid = false;
        break;
      }
      else if (isNaN(this.selection.selected[i].quantity)) {
        this._snackBar.ShowWarning('The quantity should be a number for ' + this.selection.selected[i].description);
        isValid = false;
        break;
      }
    }

    if (isValid == true) {
      LoggerHelper.log(this.selection.selected);
      var extraServices: PredefinedExtraService[] = [];
      this.selection.selected.forEach(element => {
        var extraService = new PredefinedExtraService();
        extraService.id = element.id;
        extraService.description = element.description;
        extraService.quantity = element.quantity;
        extraService.price = element.primaryFee;
        extraService.discount = element.discount;

        extraServices.push(extraService);
      });

      this.dialogRef.close(extraServices);
    }

  }

  /**
   * @name checkboxLabel
   * The label for the checkbox on the passed row
   */
  checkboxLabel(row?: ExtraServiceResponseModel): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
      }`;
  }




  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  START API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------

  /**
   * @name GetAllAvailableExtraServices
   * Get all available extra services
   */
  async GetAllAvailableExtraServices() {
    this.isLoading = true;
    await this.viewModel
      .GetAllAvailableExtraServices()
      .toPromise()
      .then((result) => {
        LoggerHelper.log(
          'New Data inside GetAllAvailableExtraServices : ' + JSON.stringify(result)
        );
        this.extraServices = result;
        this.LoadData();
      })
      .catch((error) => {
        if (error == HttpExceptionKeys.NotFound) {
          LoggerHelper.log('Error :: ' + error);
          this._snackBar.ShowWarning('Cannot found any extra service');
          this.dialogRef.close(false);
        }
        else {
          LoggerHelper.log('Error :: ' + error);
          this._snackBar.ShowWarning('There is an issue with your connection');
          this.dialogRef.close(false);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }



  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  End API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------


}
