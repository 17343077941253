import { EventEmitter, Injectable } from '@angular/core';
import { NavbarHandlerModel } from '../../models/navbar/navbar.model';
import { SidebarHandlerModel } from '../../models/sidebar/sidebar.model';

@Injectable({
  providedIn: 'root'
})


export class SharedService {

  /**
   * Nav bar handler
   */
  NavbarHandler = new EventEmitter<NavbarHandlerModel>();


  /**
   * Side bar handler
   */
  SideBarHandler = new EventEmitter<SidebarHandlerModel>();


  IssueUserData = new EventEmitter<boolean>();

  GetRequestTypes = new EventEmitter<boolean>();
}
