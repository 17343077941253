<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 manage-country-detail">

  <div *ngIf="isLoading" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 text-center">
    <loader [diameter]="50"></loader>
  </div>


  <ng-container *ngIf="!isLoading">

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
      <h1 class="title">Manage country</h1>
    </div>


    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h3 class="col-12">Please provide the name, the region and the flag link of the country that you need to add
        </h3>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-2">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <mat-form-field class="full-width">
              <mat-label>Country name</mat-label>
              <input type="text" [(ngModel)]="countryName" name="country" matInput required>
            </mat-form-field>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <mat-form-field class="full-width">
              <mat-label>Region</mat-label>
              <mat-select [(ngModel)]="region" name="region" required>
                <mat-option [value]="america">
                  {{america}}
                </mat-option>
                <mat-option [value]="europe">
                  {{europe}}
                </mat-option>
                <mat-option [value]="africa">
                  {{africa}}
                </mat-option>
                <mat-option [value]="asia">
                  {{asia}}
                </mat-option>
                <mat-option [value]="oceania">
                  {{oceania}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <mat-form-field class="full-width">
              <mat-label>Flag link</mat-label>
              <input type="text" [(ngModel)]="flagLink" name="flagLink" matInput required>
              <mat-hint align="start">You can find country flags here:
                https://www.worldometers.info/geography/flags-of-the-world/ </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>


    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
      <div *ngFor="let requestType of requestTypes" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
        <request-type-fee [countryId]="countryId" [requestType]="requestType.description"
          [requestTypeId]="requestType.id" [forBothFee]="requestType.forBothFee" [editMode]="editMode"
          [requestTypeFees]="countryFullDetails ? countryFullDetails.requestTypeFees : null">
        </request-type-fee>
      </div>
    </div>


    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 mb-5 row m-0">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 action-button">
        <button (click)="SaveCountry()" mat-button>
          <span>Save</span>
          <i class="fa fa-spinner fa-spin ml-2" *ngIf="spinnerLoading"></i>
        </button>
      </div>
    </div>
  </ng-container>

</div>
