<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 request-type-fee">

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 header-title">
    <span>{{ requestType }}</span>
  </div>


  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 row m-0">

    <ng-container *ngIf="!forBothFee">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 vertical-center">
        <span>Primary class fee:</span>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <mat-form-field class="full-width">
          <mat-label>Government fee</mat-label>
          <span matSuffix>$</span>
          <input type="text" (keyup)="SelectPrimaryGovernmentFee($event.target.value)"
            [(ngModel)]="primaryGovernmentFee" name="government fee" matInput>
        </mat-form-field>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <mat-form-field class="full-width">
          <mat-label>Professional services</mat-label>
          <span matSuffix>$</span>
          <input type="text" (keyup)="SelectPrimaryProfessionalServices($event.target.value)"
            [(ngModel)]="primaryProfessionalServices" name="professional services" matInput>
        </mat-form-field>
      </div>


      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 vertical-center">
        <span>Secondary class fee:</span>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <mat-form-field class="full-width">
          <mat-label>Government fee</mat-label>
          <span matSuffix>$</span>
          <input type="text" (keyup)="SelectSecondaryGovernmentFee($event.target.value)"
            [(ngModel)]="secondaryGovernmentFee" name="government fee" matInput>
        </mat-form-field>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <mat-form-field class="full-width">
          <mat-label>Professional services</mat-label>
          <span matSuffix>$</span>
          <input type="text" (keyup)="SelectSecondaryProfessionalServices($event.target.value)"
            [(ngModel)]="secondaryProfessionalServices" name="professional services" matInput>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="forBothFee">

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 vertical-center">
        <span>fee:</span>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <mat-form-field class="full-width">
          <mat-label>Government fee</mat-label>
          <span matSuffix>$</span>
          <input type="text" (keyup)="SelectPrimaryGovernmentFee($event.target.value)" [(ngModel)]="bothGovernmentFee"
            name="government fee" matInput>
        </mat-form-field>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <mat-form-field class="full-width">
          <mat-label>Professional services</mat-label>
          <span matSuffix>$</span>
          <input type="text" (keyup)="SelectPrimaryProfessionalServices($event.target.value)"
            [(ngModel)]="bothProfessionalServices" name="professional services" matInput>
        </mat-form-field>
      </div>

    </ng-container>

  </div>

</div>
