export class NavbarHandlerModel {
  homeActive: boolean = false;
  serviceActive: boolean = false;
  otherServicesActive: boolean = false;
  aboutUsActive: boolean = false;
  contactUsActive: boolean = false;
  signInActive: boolean = false;
  profileActive: boolean = false;
  priceActive: boolean = false;
  dashboardActive: boolean = false;
  notificationActive: boolean = false;
}
