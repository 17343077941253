import { environment } from 'src/environments/environment';

export class LoggerHelper {
  //Normal logging


  static log(message: any) {
    if (!environment.production) {
      // console.log(message);
    }
  }

  //Error logging
  static logError(message: any) {
    if (!environment.production) {
      // console.error(message);
    }
  }

  //Error logging
  static logInfo(message: any) {
    if (!environment.production) {
      // console.info(message);
    }
  }
}
