import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UserIssueRequestModel } from 'src/app/core/models/issue/request/user-issue-request.model';
import { IssueResponseModel } from 'src/app/core/models/issue/response/issue-response.model';
import { SharedService } from 'src/app/core/services/shared/shared-service.service';

@Component({
  selector: 'issue-details-view',
  templateUrl: './issue-details-view.component.html',
  styleUrls: ['./issue-details-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class IssueDetailsViewComponent implements OnInit {
  ngOnInit() {
    this.GetUserData();
  }

  @Input() issue: IssueResponseModel;
  description: string;
  maxImageFileSize: number;

  documents: File[] = [];

  userIssueRequest: UserIssueRequestModel;

  @Output() userData = new EventEmitter();


  constructor(private sharedService: SharedService) {

  }


  GetUserData() {
    this.sharedService.IssueUserData
      .subscribe((data: boolean) => {
        if (data) {
          this.userIssueRequest = new UserIssueRequestModel();
          this.userIssueRequest.id = this.issue.id;
          this.userIssueRequest.issue = this.issue.description;
          this.userIssueRequest.description = this.description && this.description.length > 0 ? this.description : null;
          this.userIssueRequest.fee = this.issue.totalAmount;
          this.ReturnUserIssueData();
        }
      });
  }



  ReturnUserIssueData() {
    var data: any = {
      userIssueRequest: this.userIssueRequest,
      documents: this.documents
    }

    this.userData.emit(data);
  }


  AddDocuments(event) {
    this.documents.push(...event.addedFiles);
  }

  RemoveDocuments(event) {
    this.documents.splice(this.documents.indexOf(event), 1);
  }

}
