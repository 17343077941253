export class EncryptionDecryptionKeys {
  /**
   * The encrypt decrypt common key with mobile
   * Key must be 16 characters
   */
  public static EncryptDecryptCommonKey = 'eswa0_{hegeo#!@#';

  /**
   * The iv key common key with mobile
   * Key must be 16 characters
   */
  public static IVCommonKey = '_18ja$301Pa_)&%V';
}
