import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserSessionResponseModel } from 'src/app/core/models/user-session/response/user-session-response.model';
import { EncryptionDecryptionHelper } from 'src/app/shared/helpers/encryption-decryption';
import { EncryptionDecryptionKeys } from 'src/app/shared/helpers/encryption-decryption-keys.helper';
import { NavigationKeys } from 'src/app/shared/helpers/navigation-keys';

@Component({
  selector: 'app-initialize-route',
  templateUrl: './initialize-route.component.html',
  encapsulation: ViewEncapsulation.None
})

export class InitializeRouteComponent implements OnInit {
  ngOnInit() {
  }

  constructor(private _router: Router) {
    this.NavigateToIndexPage();
  }


  NavigateToIndexPage() {
    if (
      UserSessionResponseModel.Instance &&
      UserSessionResponseModel.Instance.isAdmin &&
      JSON.parse(EncryptionDecryptionHelper.DecryptStringAES(
        EncryptionDecryptionKeys.EncryptDecryptCommonKey,
        EncryptionDecryptionKeys.IVCommonKey,
        UserSessionResponseModel.Instance.isAdmin)) == true) {
      this._router.navigate([NavigationKeys.Admin + '/' + NavigationKeys.AdminDashboard])
    }
    else {
      this._router.navigate([NavigationKeys.LandingPage]);
    }
  }
}
