<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 informative-dialog">

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <span class="title">Warning</span>
  </div>

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
    <span>{{data.description}}</span>
  </div>



  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]=false>Cancel</button>
    <button mat-button class="cancel" cdkFocusInitial [mat-dialog-close]=true>Yes</button>
  </mat-dialog-actions>
</div>
