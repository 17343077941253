import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { HttpExceptionKeys } from 'src/app/core/http/http-calls/http-exception-keys';
import { UserPaginatorResponseModel } from 'src/app/core/models/user/response/user-paginator-response.model';
import { UserResponseModel } from 'src/app/core/models/user/response/user-response.model';
import { SharedService } from 'src/app/core/services/shared/shared-service.service';
import { UsersViewModel } from 'src/app/core/viewmodel/users/users.viewmodel';
import { GlobalVaribales } from '../helpers/global-variables';
import { UtilityHelper } from '../helpers/utility';
import { SnackBar } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UserTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    'firstname',
    'lastname',
    'email',
    'phone',
    'country'
  ];

  dataSource = new MatTableDataSource<UserResponseModel>();
  users: UserPaginatorResponseModel;

  @Output() userOutput = new EventEmitter<UserResponseModel>();

  isLoading: boolean = true;

  pageIndex = 1;
  itemPerPage = 100;

  constructor(
    private sharedService: SharedService,
    private viewModel: UsersViewModel,
    private _snackBar: SnackBar,
    private _router: Router) {
    this.LoadData();
  }
  ngOnInit() {
  }


  async LoadData() {
    await UtilityHelper.delay(
      GlobalVaribales.ShortDelayToLoadLazyDataBetweenComponents
    ); //delay the data reading, until the data has been set from the previous page using lazy loading

    this.GetAllUsers(this.pageIndex, this.itemPerPage);
  }


  /**
     * @name filterData
     * @param value
     * Filter the info in the table
     */
  public FilterData(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }



  SetDataToTable() {
    if (this.users) {
      this.dataSource = new MatTableDataSource<UserResponseModel>(
        this.users.data
      );
      this.dataSource.paginator = this.paginator;
    }
  }


  NextPage() {
    this.pageIndex = this.pageIndex + 1;
    this.GetAllUsers(this.pageIndex, this.itemPerPage);

    LoggerHelper.log(this.pageIndex);
  }

  PreviousPage() {
    this.pageIndex = this.pageIndex - 1;
    this.GetAllUsers(this.pageIndex, this.itemPerPage);

    LoggerHelper.log(this.pageIndex);
  }


  OnRowClick(row: UserResponseModel) {

    this.userOutput.emit(row);
  }

  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  START API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------


  async GetAllUsers(page: number, perPage: number) {
    this.isLoading = true;
    this.users = undefined;
    await this.viewModel
      .GetAllUsers(page, perPage)
      .toPromise()
      .then((result) => {
        LoggerHelper.log(
          'New Data inside GetAllUsers : ' + JSON.stringify(result)
        );

        this.users = result;
        this.SetDataToTable();

      })
      .catch((error) => {
        if (error == HttpExceptionKeys.NotFound) {
          LoggerHelper.log('Error :: ' + error);
          this._snackBar.ShowWarning('Cannot found any user');
        }
        else {
          LoggerHelper.log('Error :: ' + error);
          this._snackBar.ShowWarning('There is an issue with your connection');
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }




  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  End API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------

}
