<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo" href="#/index">
      <img src="../../../assets/logo/Logo_v1.png" alt="Al Marsa logo" class="logo" />
      <!-- <img src="../../../assets/images/logo/AlTarekLogo.png" alt="" class="logo-light" height="24" /> -->
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <!-- <li class="nav-item">
          <mat-form-field class="language-dropdown no-line">
            <mat-select [(ngModel)]="selectedValue" name="food">
              <mat-option [value]="'En'">
                En
              </mat-option>
              <mat-option [value]="'Ar'">
                Ar
              </mat-option>
            </mat-select>
          </mat-form-field>
        </li> -->
        <li class="nav-item">
          <button *ngIf="!userLoggedIn" class="button-menu" (click)="HomeClick()" [class.active]="this.homeActive"
            mat-button>
            <span>Home</span>
          </button>
          <button *ngIf="userLoggedIn" class="button-menu" (click)="DashboardClick()"
            [class.active]="this.dashboardActive" mat-button>
            <span>Dashboard</span>
          </button>
        </li>
        <li class="nav-item">
          <button class="button-menu" [matMenuTriggerFor]="service" (click)="ServicesClick()"
            [class.active]="this.serviceActive || this.otherServicesActive" mat-button>
            <span>Services</span>
          </button>
          <mat-menu #service="matMenu">
            <button (click)="NavigateToComprehensiveStudy()" mat-menu-item>Comprehensive advisory</button>
            <button (click)="NavigateToRegistrationRequest()" mat-menu-item>Registration request</button>
            <!-- <button (click)="NavigateToCertificateRequest()" mat-menu-item>Issue Certificate</button> -->
            <!-- <button (click)="NavigateToRenewalCertificateRequest()" mat-menu-item>Renewal certificate</button> -->
            <!-- <button (click)="NavigateToTrademarkTransfer()" mat-menu-item>Trademark transfer</button> -->
            <button (click)="NavigateToTrademarkWatch()" mat-menu-item>Trademark watch</button>
            <button (click)="OtherServicesClick()" mat-menu-item>Other Services</button>
            <!-- <button (click)="NavigateToTrademarkSearch()" mat-menu-item>Trademark search</button> -->
          </mat-menu>
        </li>
        <!-- <li class="nav-item">
          <button class="button-menu" (click)="OtherServicesClick()" [class.active]="this.otherServicesActive"
            mat-button>
            <span>Other Services</span>
          </button>
        </li> -->
        <li class="nav-item">
          <button (click)="NavigateToAboutUs()" class="button-menu" [class.active]="this.aboutUsActive" mat-button>
            <span>About Us</span>
          </button>
        </li>
        <li class="nav-item">
          <button class="button-menu" (click)="PricesClick()" [class.active]="this.priceActive" mat-button>
            <span>Prices</span>
          </button>
        </li>
        <li class="nav-item">
          <button (click)="NavigateToContactUs()" class="button-menu" [class.active]="this.contactUsActive" mat-button>
            <span>Contact Us</span>
          </button>
        </li>
        <li *ngIf="!userLoggedIn" class="nav-item final-nav-item">
          <button class="button-menu" (click)="SignInClick()" [class.active]="this.signInActive" mat-button>
            <span>Sign In</span>
          </button>
        </li>

        <li *ngIf="userLoggedIn" class="nav-item final-nav-item">
          <button class="button-menu" (click)="NotificationClick()" mat-button [class.active]="this.notificationActive">
            <mat-icon>notifications</mat-icon>
            <span class="notification-number">{{GetNotificationCount()}}</span>
          </button>
        </li>

        <li *ngIf="userLoggedIn" class="nav-item final-nav-item">
          <button class="button-menu" mat-button [matMenuTriggerFor]="menu" [class.active-user]="this.profileActive">
            <span class="pr-1">{{firstname}}</span>
            <mat-icon>person</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <!-- <button (click)="DashboardClick()" mat-menu-item>Dashboard</button> -->
            <button (click)="ProfileClick()" mat-menu-item>Profile</button>
            <button (click)="Logout()" mat-menu-item>Logout</button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </div>
</nav>
