export class CachingHelper {
  //save item into cache
  static saveItemIntoCache(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  //get item from cache
  static getItemFromCache(key: string): string | null {
    return localStorage.getItem(key);
  }

  //remove item from cache
  static removeItemFromCache(key: string) {
    localStorage.removeItem(key);
  }
}
