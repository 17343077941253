import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { HttpExceptionKeys } from 'src/app/core/http/http-calls/http-exception-keys';
import { DocumentRequestModel } from 'src/app/core/models/document/request/document-request.model';
import { PredefinedExtraService } from 'src/app/core/models/extra-service/predefined-extra-service';
import { ExtraServiceRequestModel } from 'src/app/core/models/extra-service/request/extra-service-request.model';
import { IssueAdditionalFeeRequestModel } from 'src/app/core/models/issue-additional-fee/request/issue-additional-fee-request.model';
import { IssueCustomFeeRequestModel } from 'src/app/core/models/issue-custom-fee/request/issue-custom-fee-request.model';
import { IssueRequestModel } from 'src/app/core/models/issue/request/issue-request.model';
import { IssueViewModel } from 'src/app/core/viewmodel/issue/issue.viewmodel';
import { AddDocumentDialog } from '../dialogs/add-document-dialog/add-document-dialog.component';
import { PredefinedServiceDialog } from '../dialogs/predefined-service-dialog/predefined-service-dialog.component';
import { NavigationKeys } from '../helpers/navigation-keys';
import { SnackBar } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'admin-report-issue',
  templateUrl: './admin-report-issue.component.html',
  styleUrls: ['./admin-report-issue.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AdminReportIssueComponent implements OnInit {
  documentDisplayedColumns: string[] = [
    'name',
    'description',
    'action'
  ];


  extraServiceDisplayedColumns: string[] = [
    'description',
    'quantity',
    'price',
    'discount',
    'action'
  ];

  isLoading: boolean = false;

  @Input() serviceTypeId: number;


  documentDataSource = new MatTableDataSource<DocumentRequestModel>();
  extraServiceDataSource = new MatTableDataSource<PredefinedExtraService>();

  feeType: string;
  noFee: string = 'NoFee';
  customFee: string = 'CustomFee';
  predefinedFee: string = 'PredefinedFee';
  fee: number;
  discount?: number;

  description: string;
  documents: DocumentRequestModel[] = [];
  predefinedExtraServices: PredefinedExtraService[];
  additionalFee: number;
  additionalFeeDescription: string;

  ngOnInit() {
  }

  constructor(
    public dialog: MatDialog,
    public _snackBar: SnackBar,
    private viewModel: IssueViewModel,
    private _router: Router,
    private location: Location,) {
    this.LoadData();
  }



  async LoadData() {
    this.documentDataSource = new MatTableDataSource<DocumentRequestModel>(
      this.documents
    );

    this.extraServiceDataSource = new MatTableDataSource<PredefinedExtraService>(
      this.predefinedExtraServices
    );
  }



  OpenDocumentDialog() {
    const dialogRef = this.dialog.open(AddDocumentDialog);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        var document = new DocumentRequestModel;
        document.name = result.name;
        document.description = result.description;
        this.documents.push(document);

        this.LoadData();
      }
    });
  }


  DeleteDocument(row: DocumentRequestModel) {
    this.documents.forEach(element => {
      if (element.name == row.name) {
        this.documents.splice(this.documents.indexOf(element), 1);
      }
    });

    this.LoadData();
  }



  DeleteExtraService(row: PredefinedExtraService) {
    this.predefinedExtraServices.forEach(element => {
      if (element.id == row.id) {
        this.predefinedExtraServices.splice(this.predefinedExtraServices.indexOf(element), 1);
      }
    });

    this.LoadData();
  }



  /**
   * @name SelectFee
   * @param fee
   * Select fee
   */
  SelectFee(fee: number) {
    LoggerHelper.log(fee);
    if (fee.toString().length == 0) {
      this.fee = undefined;
    }
    else {
      if (!isNaN(fee)) {
        if (fee >= 0) {
          this.fee = fee;
        }
      }
      else {
        this.fee = undefined;
      }
    }
  }




  /**
   * @name SelectDiscount
   * @param discount
   * Select discount
   */
  SelectDiscount(discount: number) {
    LoggerHelper.log(discount);
    if (discount.toString().length == 0) {
      this.discount = undefined;
    }
    else {
      if (!isNaN(discount)) {
        if (discount >= 0) {
          this.discount = discount;
        }
      }
      else {
        this.discount = undefined;
      }
    }
  }



  /**
   * @name SelectAdditionalFee
   * @param additionalFee
   * Select additional Fee
   */
  SelectAdditionalFee(additionalFee: number) {
    LoggerHelper.log(additionalFee);
    if (additionalFee.toString().length == 0) {
      this.additionalFee = undefined;
    }
    else {
      if (!isNaN(additionalFee)) {
        if (additionalFee >= 0) {
          this.additionalFee = additionalFee;
        }
      }
      else {
        this.additionalFee = undefined;
      }
    }
  }

  ToggleFeeType(event: string) {
    LoggerHelper.log(event);
    if (event == this.noFee) {
      this.fee = 0;
      this.predefinedExtraServices = [];
      this.additionalFee = undefined;
      this.additionalFeeDescription = undefined;
    }
    else if (event == this.customFee) {
      this.fee = undefined;
      this.predefinedExtraServices = [];
      this.additionalFee = undefined;
      this.additionalFeeDescription = undefined;
    }
    else if (event == this.predefinedFee) {
      this.fee = undefined;
      this.predefinedExtraServices = [];
      this.additionalFee = undefined;
      this.additionalFeeDescription = undefined;
    }

  }

  OpenPredefinedFeeDialog() {
    const dialogRef = this.dialog.open(PredefinedServiceDialog);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.predefinedExtraServices = [];
        this.predefinedExtraServices = result;
        this.LoadData();
      }
    });
  }




  SubmitIssue() {
    var issue = new IssueRequestModel();
    issue.description = this.description;
    if (this.feeType == this.noFee) {
      issue.issueCustomFee = new IssueCustomFeeRequestModel();
      issue.issueCustomFee.fee = this.fee;
      issue.issueCustomFee.discount = null;
      issue.extraServices = null;
      issue.additionalFee = null;
    }
    else if (this.feeType == this.customFee) {
      issue.issueCustomFee = new IssueCustomFeeRequestModel();
      issue.issueCustomFee.fee = this.fee;
      issue.issueCustomFee.discount = this.discount == undefined || this.discount == null || this.discount == 0 ? null : this.discount;
      issue.extraServices = null;
      issue.additionalFee = null;
    }
    else if (this.feeType == this.predefinedFee) {
      issue.issueCustomFee = null;
      if (this.predefinedExtraServices && this.predefinedExtraServices.length > 0) {
        var extraServices: ExtraServiceRequestModel[] = [];

        this.predefinedExtraServices.forEach(element => {
          var extraService = new ExtraServiceRequestModel();
          extraService.extraServiceId = element.id;
          extraService.quantity = element.quantity;
          extraService.discount = element.discount;
          extraServices.push(extraService)
        });
        issue.extraServices = extraServices;

        if ((this.additionalFeeDescription && this.additionalFeeDescription.length > 0)
          || (this.additionalFee && this.additionalFee > 0)) {

          if ((this.additionalFeeDescription && this.additionalFeeDescription.length > 0)
            && (this.additionalFee && this.additionalFee > 0)) {
            issue.additionalFee = new IssueAdditionalFeeRequestModel();
            issue.additionalFee.fee = this.additionalFee;
            issue.additionalFee.description = this.additionalFeeDescription;
          }
          else {
            this._snackBar.ShowWarning("Please provide the additional fee and the description");
            return
          }
        }
      }
    }


    if (this.documents && this.documents.length > 0) {
      issue.additionalDocuments = this.documents;
    }


    LoggerHelper.log(issue);
    this.CreateIssue(this.serviceTypeId, issue);
  }


  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  START API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------





  /**
   * @name CreateIssue
   * @param serviceTypeId
   * @param body
   * Create an issue for a service type Id
   */
  async CreateIssue(serviceTypeId: number, body: IssueRequestModel) {
    this.isLoading = true;
    await this.viewModel
      .CreateIssue(serviceTypeId, JSON.stringify(body))
      .toPromise()
      .then((result) => {
        LoggerHelper.log(
          'New Data inside CreateIssue : ' + JSON.stringify(result)
        );
        this._router.navigate([NavigationKeys.Admin + '/' + NavigationKeys.AdminDashboard]);
      })
      .catch((error) => {
        if (error == HttpExceptionKeys.NotFound) {
          LoggerHelper.log('Error :: ' + error);
          this._snackBar.ShowWarning('There is an issue with your connection');
        }
        else {
          LoggerHelper.log('Error :: ' + error);
          this._snackBar.ShowWarning('There is an issue with your connection');
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }


  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  End API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------

}
