import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { HttpExceptionKeys } from 'src/app/core/http/http-calls/http-exception-keys';
import { UserVerificationViewModel } from 'src/app/core/viewmodel/email-verification/email-verifiation.viewmodel';
import { SnackBar } from '../../snack-bar/snack-bar.component';


@Component({
  selector: 'email-verification-dialog',
  templateUrl: './email-verification-dialog.component.html',
  styleUrls: ['./email-verification-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class EmailVerificationDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private viewModel: UserVerificationViewModel,
    private _snackBar: SnackBar
  ) { }


  ResendVerificationEmail() {
    this.ResendEmailVerification(this.data.email)
  }



  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  START API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------

  /**
   * @name ResendEmailVerification
   * @param email
   * Resend email verification
   */
  async ResendEmailVerification(email: string) {
    await this.viewModel
      .ResendVerificationEmail(email)
      .toPromise()
      .then((result) => {
        LoggerHelper.log(
          'New Data inside ResendEmailVerification : ' + JSON.stringify(result)
        );

        if (result.isSuccessfullyDone) {
          this._snackBar.ShowWarning(result.message);
        }
      })
      .catch((error) => {
        if (error == HttpExceptionKeys.NotFound) {
          LoggerHelper.log('Error :: ' + error);
          this._snackBar.ShowWarning('Cannot find this user');
        }
        else {
          LoggerHelper.log('Error :: ' + error);
          this._snackBar.ShowWarning('There is an issue with your connection');
        }
      })
      .finally(() => {
      });
  }




  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  End API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------

}
