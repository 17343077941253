export class MainKeys {

  public static combined = 'combined';
  public static logo = 'logo';
  public static word = 'word';

  public static asia = 'Asia';
  public static europe = 'Europe';
  public static america = 'America';
  public static africa = 'Africa';
  public static oceania = 'Oceania';

  public static anonymous = 'Anonymous';
  public static user = 'User';
  public static perDay = 'Per day';
  public static perWeek = 'Per week';
  public static perMonth = 'Per month';

  public static comprehensiveAdvisory = 'Comprehensive Advisory';
  public static registrationRequest = 'Registration Request';


  public static secret = 'PkW64zMe5NVdrlPVNnjo2Jy9nOb7v1Xg'; // Secret provided by Hesabe
  public static ivCode = '5NVdrlPVNnjo2Jy9'; // IV provided by Hesabe
  public static accessCode = 'c333729b-d060-4b74-a49d-7686a8353481';
  public static merchantCode = 842217;
  public static usDollar = "USD";
  public static responseUrl = "http://localhost:4200/#/payment/receipts";
  public static responseSearchUrl = "http://localhost:4200/#/payment/search";
  public static failureUrl = "http://localhost:4200/#/payment-failed";
  public static paymentUrl = "https://sandbox.hesabe.com/payment?data=";
  public static gmailMailito = "https://mail.google.com/mail/?view=cm&fs=1&to=info@altarek.com"
}
