import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-calls/global-variables';
import { HttpCall } from '../../http/http-calls/http-calls';

@Injectable({
  providedIn: 'root'
})
export class UsersApplicationService {

  constructor(
    private globalApiVariables: GlobalApiVariables,
    private http: HttpCall) {
  }


  /**
   * @name LoginUser
   * @param body
   * Login user
   */
  public LoginUser(body: any) {
    var data = this.http.PostAsync(
      false,
      body,
      this.globalApiVariables.LoginUrl
    );
    return data;
  }


  /**
   * @name LogoutUser
   * @param userId
   * Logout user
   */
  public LogoutUser(userId: number) {
    var data = this.http.GetAsync(
      true,
      this.globalApiVariables.LogoutUrl,
      userId
    );
    return data;
  }


  /**
   * @name ForgotPassword
   * @param body
   * forgot password
   */
  public ForgotPassword(body: any) {
    var data = this.http.PostAsync(
      false,
      body,
      this.globalApiVariables.RecoveryPasswordUrl,
    );

    return data;
  }


  /**
   * @name SignUpUser
   * @param body
   * Sign up a new user
   */
  public SignUpUser(body: any) {
    var data = this.http.PostAsync(
      false,
      body,
      this.globalApiVariables.SignUpUrl
    );
    return data;
  }


  /**
   * @name VerifyEmail
   * @param verificationToken
   * Verify user email
   */
  public VerifyEmail(verificationToken: string) {
    var data = this.http.GetAsync(
      false,
      this.globalApiVariables.VerifyEmail,
      verificationToken
    );
    return data;
  }


  /**
   * @name ResendVerificationEmail
   * @param email
   * Resend verification email
   */
  public ResendVerificationEmail(email: string) {
    var data = this.http.GetAsync(
      false,
      this.globalApiVariables.ResendEmailVerification,
      email
    );
    return data;
  }


  /**
   * Get all users
   */
  public GetAllUsers(page: number, perPage: number) {
    var data = this.http.GetAsync(
      true,
      this.globalApiVariables.ApiGetAllUsers,
      page,
      perPage
    );

    return data;
  }

  /**
   * @name UpdateUser
   * @param body
   * update user
   */
  public UpdateUser(userId: number, body: any) {
    var data = this.http.PutAsync(
      true,
      body,
      this.globalApiVariables.ApiUpdateUser,
      userId
    );

    return data;
  }


  /**
   * @name GetUserById
   * @param userId
   * Get user by id
   */
  public GetUserById(userId: number) {
    var data = this.http.GetAsync(
      true,
      this.globalApiVariables.ApiGetUserById,
      userId
    );

    return data;
  }



  /**
   * @name SendContactUs
   * @param body
   * Send contact us
   */
  public SendContactUs(body: any) {
    var data = this.http.PostAsync(
      false,
      body,
      this.globalApiVariables.ApiSendEmailForContactUs,
    );

    return data;
  }

}
