import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-calls/global-variables';
import { HttpCall } from '../../http/http-calls/http-calls';

@Injectable({
  providedIn: 'root'
})
export class IssuesApplicationService {

  constructor(
    private globalApiVariables: GlobalApiVariables,
    private http: HttpCall) {
  }




  /**
   * @name CreateIssue
   * @param serviceTypeId
   * @param body
   * Create an issue for a service type Id
   */
  public CreateIssue(serviceTypeId: number, body: any) {
    var data = this.http.PostAsync(
      true,
      body,
      this.globalApiVariables.ApiCreateIssue,
      serviceTypeId
    );
    return data;
  }



  /**
   * @name CheckOutIssue
   * @param userId
   * @param body
   * Check out issue
   */
  CheckOutIssue(userId: number, body: any) {
    var data = this.http.PostAsync(
      true,
      body,
      this.globalApiVariables.ApiCheckOutIssue,
      userId
    );
    return data;
  }

}
