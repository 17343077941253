<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 user-table">


  <div *ngIf="isLoading" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 text-center">
    <loader [diameter]="50"></loader>
  </div>



  <!-- START SEARCH BAR -->
  <div *ngIf="!isLoading && users " class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-5">
    <mat-form-field class="search-user col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
      <mat-label>Search</mat-label>
      <input type="text" matInput placeholder="search" (keyup)="FilterData($event.target.value)" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <!-- END SEARCH BAR -->


  <div *ngIf="!isLoading && users" class="user-table col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
    <table mat-table [dataSource]="dataSource">
      <!-- firstname Column -->
      <ng-container matColumnDef="firstname">
        <th mat-header-cell *matHeaderCellDef>
          <span class="table-header-text">Firstname</span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-body-text-small">
          {{ element.firstname }}
        </td>
      </ng-container>

      <!-- lastname Column -->
      <ng-container matColumnDef="lastname">
        <th mat-header-cell *matHeaderCellDef>
          <span class="table-header-text">Lastname</span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-body-text-small">
          {{ element.lastname }}
        </td>
      </ng-container>


      <!-- email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>
          <span class="table-header-text">Email</span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-body-text-small">
          {{ element.email }}
        </td>
      </ng-container>


      <!-- phone Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>
          <span class="table-header-text">Phone</span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-body-text-small">
          {{element.internationalPhoneCode}} {{ element.phone }}
        </td>
      </ng-container>


      <!-- country Column -->
      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef>
          <span class="table-header-text">Country</span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-body-text-small">
          {{ element.country }}
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="OnRowClick(row)"></tr>
    </table>
  </div>

  <div *ngIf="!isLoading && users" class="pagination">
    <div class="page-size">
      <div class="page-size-label"> items per page:</div>
      <div class="page-size-label"> {{users.per_page}}</div>
    </div>
    <div class="pagination-action">
      <div class="range-label">{{users.from}} - {{users.to}} of {{users.total}}</div>
      <button [disabled]="!users.prev_page_url" (click)="PreviousPage()" mat-button>
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button [disabled]="!users.next_page_url" (click)="NextPage()" mat-button>
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>

</div>
