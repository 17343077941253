<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 predefined-service-dialog">

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <span class="title">Predefined fees</span>
  </div>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
    <span>Please select one or multiple extra fee and specify the quantity you need</span>
  </div>

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4 extra-service-table body">

    <div *ngIf="isLoading" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 mb-5 text-center">
      <mat-spinner [diameter]=50 style="margin-right: auto; margin-left: auto;"></mat-spinner>
    </div>

    <table *ngIf="!isLoading" mat-table [dataSource]="dataSource">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox color="primary" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>


      <!-- description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          <span class="table-header-text">Description</span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-body-text-small">
          {{ element.description }}
        </td>
      </ng-container>

      <!-- quantity Column -->
      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef>
          <span class="table-header-text">Quantity</span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-body-text-small">

          <mat-form-field>
            <mat-label>quantity</mat-label>
            <input matInput [(ngModel)]="element.quantity" placeholder="0" name="quantity">
          </mat-form-field>


        </td>
      </ng-container>


      <!-- price Column -->
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>
          <span class="table-header-text">Price</span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-body-text-small">
          {{element.primaryFee}}$
        </td>
      </ng-container>

      <!-- discount Column -->
      <ng-container matColumnDef="discount">
        <th mat-header-cell *matHeaderCellDef>
          <span class="table-header-text">Discount</span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-body-text-small">
          <mat-form-field>
            <mat-label>discount</mat-label>
            <span matSuffix>%</span>
            <input matInput [(ngModel)]="element.discount" placeholder="0" name="discount">
          </mat-form-field>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]=false>Cancel</button>
    <div class="action-button">
      <button mat-button (click)="AddExtraFee()" [disabled]="selection.selected.length == 0"
        class="ml-2 mr-2">Add</button>
    </div>
  </mat-dialog-actions>


</div>
