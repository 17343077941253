import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { FeatherModule } from 'angular-feather';
import {
  ArrowRightCircle, Calendar, Clock, Facebook, Instagram, Layout, LifeBuoy, Link, Linkedin, Mail, MapPin, PhoneCall,
  PieChart, Rss, Send, Server,
  Triangle, Twitter, User
} from 'angular-feather/icons';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AdminReportIssueComponent } from './admin-report-issue/admin-report-issue.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { AddDocumentDialog } from './dialogs/add-document-dialog/add-document-dialog.component';
import { EmailVerificationDialog } from './dialogs/email-verification-dialog/email-verification-dialog.component';
import { InformativeDialog } from './dialogs/Informative-dialog/informative-dialog.component';
import { PredefinedServiceDialog } from './dialogs/predefined-service-dialog/predefined-service-dialog.component';
import { PreviewFilesDialog } from './dialogs/preview-files-dialog/preview-files-dialog.component';
import { SubClassDialog } from './dialogs/subclass-dialog/subclass-dialog.component';
import { DropZoneComponent } from './drop-zone/drop-zone.component';
import { FeaturesComponent } from './features/features.component';
import { FooterComponent } from './footer/footer.component';
import { IssueDetailsViewComponent } from './issue-details-view/issue-details-view.component';
import { LoaderComponent } from './loader/loader.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { PricingComponent } from './pricing/pricing.component';
import { RequestTypeFeeComponent } from './request-type-fee/request-type-fee.component';
import { ScrollspyDirective } from './scrollspy.directive';
import { ServicesComponent } from './services/services.component';
import { TeamCardComponent } from './team-card/team-card.component';
import { TrademarkInformationComponent } from './trademark-information/trademark-information.component';
import { UserTableComponent } from './user-table/user-table.component';
import { WidgetPieChartComponent } from './widget-pie-chart/widget-pie-chart.component';



const icons = {
  Mail, Link, PhoneCall, Clock, MapPin, Facebook, Twitter, Instagram, Linkedin, Send, Calendar, User, Server, Rss, Layout, LifeBuoy,
  ArrowRightCircle, PieChart, Triangle
};

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    FeaturesComponent,
    PricingComponent,
    BlogComponent,
    ContactComponent,
    ServicesComponent,
    FooterComponent,
    NavBarComponent,
    EmailVerificationDialog,
    LoaderComponent,
    ScrollspyDirective,
    TrademarkInformationComponent,
    PreviewFilesDialog,
    InformativeDialog,
    AdminReportIssueComponent,
    AddDocumentDialog,
    PredefinedServiceDialog,
    IssueDetailsViewComponent,
    DropZoneComponent,
    WidgetPieChartComponent,
    SubClassDialog,
    TeamCardComponent,
    SubClassDialog,
    NotificationCardComponent,
    RequestTypeFeeComponent,
    UserTableComponent],
  imports: [
    CommonModule,
    FeatherModule.pick(icons),
    MatSelectModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatTableModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    NgxDropzoneModule,
  ],
  entryComponents: [PreviewFilesDialog, AddDocumentDialog, PredefinedServiceDialog, InformativeDialog, SubClassDialog],
  // tslint:disable-next-line: max-line-length
  exports: [
    FeaturesComponent,
    PricingComponent,
    BlogComponent,
    ContactComponent,
    ServicesComponent,
    FooterComponent,
    NavBarComponent,
    FeatherModule,
    LoaderComponent,
    ScrollspyDirective,
    TrademarkInformationComponent,
    PreviewFilesDialog,
    InformativeDialog,
    AdminReportIssueComponent,
    AddDocumentDialog,
    PredefinedServiceDialog,
    IssueDetailsViewComponent,
    DropZoneComponent,
    WidgetPieChartComponent,
    SubClassDialog,
    NotificationCardComponent,
    RequestTypeFeeComponent,
    TeamCardComponent,
    NotificationCardComponent,
    UserTableComponent]
})
export class SharedModule { }
