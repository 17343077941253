<!-- Footer Start -->
<section class="footer" style="background-image: url(assets/images/footer-bg.png)">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="mb-5">
          <img src="../../../assets/logo/Logo_v1-removebg-preview.png" alt="" class="logo">
          <p class="text-white-50 my-4 font-size-15">Whatever the level of support you require, we are sure that we will
            have a package that meets your needs.</p>
          <ul class="list-inline footer-social-icon-content">
            <li class="list-inline-item mr-4"><a href="javascript: void(0);" class="footer-social-icon">
                <i-feather name="facebook"></i-feather>
              </a></li>
            <li class="list-inline-item mr-4"><a href="javascript: void(0);" class="footer-social-icon">
                <i-feather name="twitter"></i-feather>
              </a></li>
            <li class="list-inline-item mr-4"><a href="javascript: void(0);" class="footer-social-icon">
                <i-feather name="instagram"></i-feather>
              </a></li>
            <li class="list-inline-item"><a href="javascript: void(0);" class="footer-social-icon">
                <i-feather name="linkedin"></i-feather>
              </a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7 offset-lg-1">
        <div class="row">
          <div class="col-md-4">
            <h4 class="text-white text-uppercase mb-3">Navigation</h4>
            <ul class="list-unstyled footer-sub-menu">
              <li><a (click)="navigateToLandingPage()" class="footer-link">Home</a></li>
              <li><a (click)="navigateToAboutUs()" class="footer-link">About Us</a></li>
              <li><a (click)="navigateToPrices()" class="footer-link">Prices</a></li>
              <li><a (click)="navigateToContactUs()" class="footer-link">Contact Us</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h4 class="text-white text-uppercase mb-3">Services</h4>
            <ul class="list-unstyled footer-sub-menu">
              <li><a (click)="navigateToComprehensiveAdvisory()" class="footer-link">Comprehensive advisory</a></li>
              <li><a (click)="navigateToRegistrationRequest()" class="footer-link">Registration request</a></li>
              <!-- <li><a href="javascript: void(0);" class="footer-link">Renew certificate</a></li> -->
              <!-- <li><a href="javascript: void(0);" class="footer-link">Transfer certificate</a></li> -->
              <!-- <li><a href="javascript: void(0);" class="footer-link">Trademark search</a></li> -->
              <li><a (click)="navigateToTrademarkWatch()" class="footer-link">Trademark watch</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h4 class="text-white text-uppercase mb-3">Contact</h4>
            <ul class="list-unstyled footer-sub-menu">
              <li><a class="footer-link">Advisory@almarsapro.com</a></li>
              <li><a class="footer-link">Location : Kuwait City, Kuwait</a></li>
              <li><a class="footer-link">tel : +965 552 69245</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="footer-alt py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <p class="text-white-50 font-size-15 mb-0">{{year}} © Al Marsa. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Footer End -->
