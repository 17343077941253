<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 add-document-dialog">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
    <span class="title">Add document</span>
  </div>


  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4 p-0 body">

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <mat-form-field class="input-width">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="name" name="name input" required>
      </mat-form-field>
    </div>


    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
      <mat-form-field class="input-width">
        <mat-label>Description</mat-label>
        <textarea rows="2" matInput [(ngModel)]="description" name="description area"></textarea>
      </mat-form-field>
    </div>
  </div>

  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]=false>Cancel</button>
    <div class="action-button">
      <button mat-button (click)="AddDocument()" [disabled]="!name || name.length == 0" class="ml-2 mr-2">Add</button>
    </div>
  </mat-dialog-actions>
</div>
