import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent {

  @Input() diameter: number = 50;

}
