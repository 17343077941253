<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-5 admin-report-issue">

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <h3 class="issue-description">Additional request description</h3>
    <span>If you have any additional request please provide your request description for your client</span>
  </div>


  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
    <mat-form-field class="text-area">
      <mat-label>description</mat-label>
      <textarea rows="4" matInput [(ngModel)]="description" name="description area" required></textarea>
    </mat-form-field>
  </div>

  <div class="row m-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
    <div class="col-8 p-0">
      <h3 class="additional-files">Additional documents</h3>
      <span>If you need additional documents, please enter them by clicking on the button</span>
    </div>
    <div class="col-4 add-file-button">
      <button (click)="OpenDocumentDialog()" class="mt-2" mat-mini-fab aria-label="Add files button">
        <mat-icon>note_add</mat-icon>
      </button>
    </div>
  </div>


  <div *ngIf="documents && documents.length > 0"
    class="col-xl-6 col-lg-6 col-md-10 col-sm-12 col-xs-12 mt-3 data-table document-table">

    <table mat-table [dataSource]="documentDataSource">
      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          <span class="table-header-text">Name</span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-body-text-small">
          {{ element.name }}
        </td>
      </ng-container>

      <!-- description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          <span class="table-header-text">Description</span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-body-text-small">
          <span *ngIf="element.description && element.description.length > 0">{{ element.description }}</span>
          <span *ngIf="!element.description || element.description.length == 0">-</span>
        </td>
      </ng-container>


      <!-- action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let element" class="table-body-text-small">
          <button mat-button (click)="DeleteDocument(element)" class="float-right">Delete</button>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="documentDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: documentDisplayedColumns"></tr>
    </table>
  </div>


  <div class="row m-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 p-0">
      <h3 class="additional-files">Fee</h3>
      <span>Please select what type of fee you need to set for this issue</span>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
      <mat-button-toggle-group (change)="ToggleFeeType($event.value)" [(ngModel)]="feeType" class="mt-2" name="fee type"
        aria-label="Fee type">
        <mat-button-toggle [value]="noFee">No fee</mat-button-toggle>
        <mat-button-toggle [value]="customFee">Custom fee</mat-button-toggle>
        <mat-button-toggle [value]="predefinedFee">Predefined fee</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>


  <div *ngIf="feeType == noFee || feeType == customFee" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <mat-form-field>
        <mat-label>Fee</mat-label>
        <span matSuffix>$</span>
        <input matInput [disabled]="feeType == noFee ? true : false" (keyup)="SelectFee($event.target.value)"
          [(ngModel)]="fee" name="fee">
      </mat-form-field>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <mat-form-field *ngIf="feeType==customFee">
        <mat-label>Discount</mat-label>
        <span matSuffix>%</span>
        <input matInput (keyup)="SelectDiscount($event.target.value)" [(ngModel)]="discount" name="discount">
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="feeType == predefinedFee" clas="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="row m-0  col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
      <span class="mt-1">Click here to select some predefined fee from your system</span>

      <div class="action-button ml-3">
        <button (click)="OpenPredefinedFeeDialog()" mat-button>
          <span> Predefined fee </span>
        </button>
      </div>
    </div>

    <div *ngIf="predefinedExtraServices && predefinedExtraServices.length > 0"
      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 data-table extra-service-table">

      <table mat-table [dataSource]="extraServiceDataSource">
        <!-- description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>
            <span class="table-header-text">Description</span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-body-text-small">
            {{ element.description }}
          </td>
        </ng-container>

        <!-- quantity Column -->
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>
            <span class="table-header-text">Quantity</span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-body-text-small">
            {{ element.quantity }}
          </td>
        </ng-container>


        <!-- price Column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>
            <span class="table-header-text">Price</span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-body-text-small">
            {{element.price}} $
          </td>
        </ng-container>


        <!-- discount Column -->
        <ng-container matColumnDef="discount">
          <th mat-header-cell *matHeaderCellDef>
            <span class="table-header-text">Discount</span>
          </th>
          <td mat-cell *matCellDef="let element" class="table-body-text-small">
            <span *ngIf="element.discount && element.discount.length > 0">{{ element.discount }}%</span>
            <span *ngIf="!element.discount">-</span>
          </td>
        </ng-container>


        <!-- action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let element" class="table-body-text-small">
            <button mat-button (click)="DeleteExtraService(element)" class="float-right">Delete</button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="extraServiceDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: extraServiceDisplayedColumns"></tr>
      </table>
    </div>


    <div *ngIf="predefinedExtraServices && predefinedExtraServices.length > 0"
      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 p-0">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h3 class="additional-files">Additional charges</h3>
        <span>if you have any additional charges please provide the description and the charge</span>
      </div>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 p-0">

        <mat-form-field class="mr-3 ml-3 additional-fee-description">
          <mat-label>Description</mat-label>
          <input matInput [(ngModel)]="additionalFeeDescription" name="additional Fee Description">
        </mat-form-field>


        <mat-form-field class="mr-3 ml-3">
          <mat-label>Additional Fee</mat-label>
          <span matSuffix>$</span>
          <input matInput [(ngModel)]="additionalFee" (keyup)="SelectAdditionalFee($event.target.value)"
            name="additional Fee">
        </mat-form-field>

      </div>
    </div>
  </div>

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 p-0">
    <div class="col-12 action-button">
      <button [disabled]="
        !description ||
        description.length == 0 ||
        !feeType ||
        (feeType == customFee && (!fee || fee.toString().length == 0)) ||
        (feeType == predefinedFee && (!predefinedExtraServices || predefinedExtraServices.length == 0))"
        (click)="SubmitIssue()" mat-button>
        <span class="mr-2">Submit</span>
        <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
      </button>
    </div>
  </div>

</div>
