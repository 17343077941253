import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoggerHelper } from '../core/helpers/logging/logger-helpers';
import { NavbarHandlerModel } from '../core/models/navbar/navbar.model';
import { NotificationResponseModel } from '../core/models/notification/response/notification-response.model';
import { UserSessionResponseModel } from '../core/models/user-session/response/user-session-response.model';
import { SharedService } from '../core/services/shared/shared-service.service';
import { NotificationViewModel } from '../core/viewmodel/notification/notification.viewmodel';
import { EncryptionDecryptionHelper } from '../shared/helpers/encryption-decryption';
import { EncryptionDecryptionKeys } from '../shared/helpers/encryption-decryption-keys.helper';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class PagesComponent implements OnInit {
  ngOnInit() {
    this.ChangeNavBarActive();
  }


  notifications: NotificationResponseModel[];

  constructor(private sharedService: SharedService,
    private notificationViewModel: NotificationViewModel) {
    this.GetNotificationCenter();
  }

  ChangeNavBarActive() {
    var navbarHandler = new NavbarHandlerModel();
    navbarHandler.homeActive = false;
    navbarHandler.serviceActive = false;
    navbarHandler.otherServicesActive = false;
    navbarHandler.aboutUsActive = false;
    navbarHandler.contactUsActive = false;
    navbarHandler.signInActive = false;
    navbarHandler.priceActive = false;
    navbarHandler.dashboardActive = false;
    navbarHandler.notificationActive = false;
    this.sharedService.NavbarHandler.emit(navbarHandler);
  }


  GetNotificationCenter() {
    if (
      UserSessionResponseModel.Instance &&
      UserSessionResponseModel.Instance.id &&
      JSON.parse(EncryptionDecryptionHelper.DecryptStringAES(
        EncryptionDecryptionKeys.EncryptDecryptCommonKey,
        EncryptionDecryptionKeys.IVCommonKey,
        UserSessionResponseModel.Instance.id)) > 0) {

      var userId = JSON.parse(EncryptionDecryptionHelper.DecryptStringAES(
        EncryptionDecryptionKeys.EncryptDecryptCommonKey,
        EncryptionDecryptionKeys.IVCommonKey,
        UserSessionResponseModel.Instance.id));
      this.GetNotificationsByUserId(userId);
    }

  }







  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  START API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------




  /**
   * @name GetNotificationsByUserId
   * @param userId
   * @returns data
   * Get notifications by user id
   */
  async GetNotificationsByUserId(userId: number) {
    await this.notificationViewModel
      .GetNotificationsByUserId(userId)
      .toPromise()
      .then((result) => {
        LoggerHelper.log(
          'New Data inside GetNotificationsByUserId : ' + JSON.stringify(result)
        );
        this.notifications = result;
        this.notificationViewModel.SaveNotifiationInCache(this.notifications);
      })
      .catch((error) => {
        LoggerHelper.log('Error :: ' + error);

      })
      .finally(() => {
      });
  }


  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  End API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------

}
