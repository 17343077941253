import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
// Charts
import { Chart } from 'chart.js/dist/Chart.min.js';
import { PieChartListModel } from 'src/app/core/models/pie-chart/pie-chart-list.model';
import { GlobalVaribales } from '../helpers/global-variables';
import { UtilityHelper } from '../helpers/utility';

@Component({
  selector: 'widget-pie-chart',
  templateUrl: './widget-pie-chart.component.html',
  styleUrls: ['./widget-pie-chart.component.scss']
})
export class WidgetPieChartComponent implements OnInit, OnChanges {
  // Public properties
  @ViewChild('chart') chart: ElementRef;
  @Input() PieChartData: PieChartListModel;
  dataNumbers: number[] = [];
  dataBackgrounds: string[] = [];
  dataLabels: string[] = [];

  constructor() {

  }
  ngOnChanges(changes: SimpleChanges): void {

  }



  /**
   * On init
   */
  async ngOnInit() {
    await UtilityHelper.delay(
      GlobalVaribales.DelayToLoadLazyDataBetweenComponents
    );
    this.initChartJS();
  }

  /** Init chart */
  async initChartJS() {
    await UtilityHelper.delay(
      GlobalVaribales.DelayToLoadLazyDataBetweenComponents
    );
    //Loading data into arrays to be used by the chart
    for (var i = 0; i < this.PieChartData.details.length; i++) {
      this.dataNumbers[i] = this.PieChartData.details[i].value;
    }
    for (var i = 0; i < this.PieChartData.details.length; i++) {
      this.dataBackgrounds[i] = this.PieChartData.details[i].backgrounds;
    }
    for (var i = 0; i < this.PieChartData.details.length; i++) {
      this.dataLabels[i] = this.PieChartData.details[i].label;
    }

    //Initializing data in the chart
    var dataChartDoughnut = {
      datasets: [
        {
          data: this.dataNumbers,
          backgroundColor: this.dataBackgrounds
        }
      ],
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: this.dataLabels
    };

    //Chart Settings
    const chart = new Chart(this.chart.nativeElement, {
      type: 'doughnut',
      data: dataChartDoughnut,
      options: {
        title: {
          display: false
        },
        tooltips: {
          intersect: false,
          mode: 'nearest',
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10
        },
        legend: {
          display: false
        },

        responsive: false,
        maintainAspectRatio: false,
        barRadius: 4,
        scales: {
          xAxes: [
            {
              display: false,
              gridLines: false,
              stacked: true
            }
          ],
          yAxes: [
            {
              display: false,
              stacked: true,
              gridLines: false
            }
          ]
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }
}
