import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBar {
  DURATION_TIME = 5000;
  constructor(public _snackBar: MatSnackBar) { }

  /**
   * @name ShowWarning
   * @param message
   */
  ShowWarning(message: string) {
    this._snackBar.open(message, null, {
      duration: this.DURATION_TIME
    });
  }

}
