import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TeamCardComponent implements OnInit {
  ngOnInit() {
  }

  @Input() profile : string;
  @Input() name: string;
  @Input() position : string;
  @Input() description : string;

  constructor() {

  }

}
