import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { ClassFeeResponseModel } from 'src/app/core/models/class/response/class-fee-response.model';
import { FileResponseModel } from 'src/app/core/models/file/response/file-response.model';
import { ServiceTypeCountryFeeResponseModel } from 'src/app/core/models/service-type/response/service-type-country-fee-response.model';
import { TrademarkTransferOwnerResponseModel } from 'src/app/core/models/trademark-transfer/response/trademark-transfer-owner-response.model';
import { PreviewFilesDialog } from '../dialogs/preview-files-dialog/preview-files-dialog.component';

@Component({
  selector: 'trademark-information',
  templateUrl: './trademark-information.component.html',
  styleUrls: ['./trademark-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TrademarkInformationComponent implements OnInit {
  ngOnInit() {
    this.SplitData(this.countryClassDetails)
  }

  @Input() firstname: string;
  @Input() lastname: string;
  @Input() email: string;
  @Input() date: string;
  @Input() mark: string;
  @Input() logo: string;
  @Input() description: string;
  @Input() countryClassDetails: ServiceTypeCountryFeeResponseModel[];
  @Input() files: FileResponseModel[];
  @Input() report: string;
  @Input() filingDate: string;
  @Input() expirationDate: string;
  @Input() registrationDate: string;
  @Input() certification: string;
  @Input() registrationNumber: string;
  @Input() trademarkTransferOwner: TrademarkTransferOwnerResponseModel;



  primaryCountry: ServiceTypeCountryFeeResponseModel;
  secondaryCountries: ServiceTypeCountryFeeResponseModel[];
  classes: ClassFeeResponseModel[] = [];

  constructor(public dialog: MatDialog) {

  }



  SplitData(countryClassDetails: ServiceTypeCountryFeeResponseModel[]) {
    this.primaryCountry = countryClassDetails.find(f => f.isPrimary == true);
    this.secondaryCountries = countryClassDetails.filter(f => f.isPrimary == false);

    this.classes = [];
    countryClassDetails.forEach(element => {
      element.classes.forEach(element => {
        if (!this.classes.find(f => f.id == element.id)) {
          this.classes.push(element);
        }
      });
    });

    LoggerHelper.log(this.classes);

  }


  OpenFilesDialog() {
    const dialogRef = this.dialog.open(PreviewFilesDialog, {
      data: {
        files: this.files
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  OpenReport() {
    window.open(this.report, "_blank");
  }


  OpenCertification() {
    window.open(this.certification, "_blank");
  }


}
