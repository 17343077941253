import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CachingHelper } from 'src/app/core/helpers/caching/caching';
import { CachingKeys } from 'src/app/core/helpers/caching/caching-keys';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { NavbarHandlerModel } from 'src/app/core/models/navbar/navbar.model';
import { NotificationResponseModel } from 'src/app/core/models/notification/response/notification-response.model';
import { UserSessionResponseModel } from 'src/app/core/models/user-session/response/user-session-response.model';
import { SharedService } from 'src/app/core/services/shared/shared-service.service';
import { LoginViewModel } from 'src/app/core/viewmodel/login/login.viewmodel';
import { EncryptionDecryptionHelper } from '../helpers/encryption-decryption';
import { EncryptionDecryptionKeys } from '../helpers/encryption-decryption-keys.helper';
import { NavigationKeys } from '../helpers/navigation-keys';
import { SnackBar } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

/**
 * Index-1 component
 */
export class NavBarComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  ngOnInit() {
    this.NavbarHandler();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }


  selectedValue: string = 'En';

  homeActive: boolean = false;
  dashboardActive: boolean = false;
  serviceActive: boolean = false;
  otherServicesActive: boolean = false;
  aboutUsActive: boolean = false;
  contactUsActive: boolean = false;
  signInActive: boolean = false;
  notificationActive: boolean = false;
  profileActive: boolean = false;
  priceActive: boolean = false;

  userLoggedIn: boolean = false;
  firstname: string;


  notificationNumber: number;

  constructor(
    private sharedService: SharedService,
    private loginViewModel: LoginViewModel,
    private _router: Router,
    private _snackBar: SnackBar
  ) {
    this.GetNotificationCount();
  }


  CheckUserIfAlreadyLoggedIn() {
    if (JSON.parse(CachingHelper.getItemFromCache(CachingKeys.UserSession))) {
      LoggerHelper.log('Is authenticated');
      this.firstname = UserSessionResponseModel.Instance.firstname;
      this.userLoggedIn = true;
    }
    else {
      this.userLoggedIn = false;
    }
  }



  GetNotificationCount() {
    var notifications = NotificationResponseModel.Instance;
    this.notificationNumber = notifications.length;
    return this.notificationNumber;
  }


  NavbarHandler() {
    this.sharedService.NavbarHandler.pipe(takeUntil(this.destroyed$))
      .subscribe((data: NavbarHandlerModel) => {
        this.homeActive = data.homeActive;
        this.serviceActive = data.serviceActive;
        this.otherServicesActive = data.otherServicesActive;
        this.aboutUsActive = data.aboutUsActive;
        this.contactUsActive = data.contactUsActive;
        this.signInActive = data.signInActive;
        this.profileActive = data.profileActive;
        this.priceActive = data.priceActive;
        this.dashboardActive = data.dashboardActive;
        this.notificationActive = data.notificationActive;
        this.CheckUserIfAlreadyLoggedIn();
      });
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  HomeClick() {
    this._router.navigate([NavigationKeys.LandingPage]);
  }


  ServicesClick() {
  }


  OtherServicesClick() {
    this._router.navigate([NavigationKeys.OtherServices]);
  }


  PricesClick() {
    this._router.navigate([NavigationKeys.PricePage]);
  }

  DashboardClick() {
    this._router.navigate([NavigationKeys.DashboardPage]);
  }


  SignInClick() {
    this._router.navigate([NavigationKeys.SignInPage]);
  }


  NotificationClick() {
    this._router.navigate([NavigationKeys.UserNotificationPage]);
  }

  ProfileClick() {
    this._router.navigate([NavigationKeys.ProfilePage + '/general']);
  }

  Logout() {
    this.LogoutUser(EncryptionDecryptionHelper.DecryptStringAES(
      EncryptionDecryptionKeys.EncryptDecryptCommonKey,
      EncryptionDecryptionKeys.IVCommonKey, UserSessionResponseModel.Instance.id));
  }







  NavigateToComprehensiveStudy() {
    this._router.navigate([NavigationKeys.ComprehensiveStudyPage]);
  }


  NavigateToRegistrationRequest() {
    this._router.navigate([NavigationKeys.RegistrationRequestPage]);
  }


  NavigateToCertificateRequest() {
    this._router.navigate([NavigationKeys.DashboardPage + '/certificate-request']);
  }



  NavigateToRenewalCertificateRequest() {
    this._router.navigate([NavigationKeys.RenewCertificateRequestPage]);
  }



  NavigateToTrademarkTransfer() {
    this._router.navigate([NavigationKeys.TrademarkTransferPage]);
  }



  NavigateToTrademarkWatch() {
    this._router.navigate([NavigationKeys.TrademarkWatchInfoPage]);
  }


  NavigateToTrademarkSearch() {
    this._router.navigate([NavigationKeys.TrademarkSearchPage]);
  }



  NavigateToAboutUs() {
    this._router.navigate([NavigationKeys.AboutUsPage]);
  }


  NavigateToContactUs() {
    this._router.navigate([NavigationKeys.ContactUsPage]);
  }


  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  START API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------


  async LogoutUser(userId: number) {
    await this.loginViewModel
      .LogoutUser(userId)
      .toPromise()
      .then((result) => {
        LoggerHelper.log(
          'New Data inside LogoutUser : ' + JSON.stringify(result)
        );

        if (result.isSuccessfullyDone) {
          this.loginViewModel.ClearSessionData();
          this._router.navigate([NavigationKeys.SignInPage]);
        }
      })
      .catch((error) => {
        LoggerHelper.log('Error :: ' + error);
        this._snackBar.ShowWarning('There is a issue with your connection');
      })
      .finally(() => {
      });
  }



  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  End API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------

}
