<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 team-card h-100">

  <div class="col-12 text-center image-div">
    <img class="img-style"
      [src]="profile"
      alt="person">
  </div>

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 h-100 body-information">
    <div class="col-12 text-center name-text">
      <span>{{name}}</span>
    </div>
    <div class="col-12 text-center mt-3 position">
      <span>{{position}}</span>
    </div>
    <div class="col-12 text-center mt-4 mb-3">
      <span>{{description}}</span>
    </div>

  </div>

</div>
