import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { HttpExceptionKeys } from 'src/app/core/http/http-calls/http-exception-keys';
import { ClassRequestModel } from 'src/app/core/models/class/request/class-request.model';
import { SubClassRequestModel } from 'src/app/core/models/subclass/request/subclass-request.model';
import { SubClassResponseModel } from 'src/app/core/models/subclass/response/subclass-response.model';
import { SubClassViewModel } from 'src/app/core/viewmodel/subclass/subclass.viewmodel';
import { SnackBar } from '../../snack-bar/snack-bar.component';

@Component({
  selector: 'subclass-dialog',
  templateUrl: './subclass-dialog.component.html',
  styleUrls: ['./subclass-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class SubClassDialog {
  isLoading: boolean = true;
  toggleValue: number;
  previousToggleValue: number;

  displayedColumns: string[] = ['select', 'basicNumber', 'name'];
  dataSource = new MatTableDataSource<SubClassResponseModel>();
  selection = new SelectionModel<SubClassResponseModel>(true, []);

  classesRequest: ClassRequestModel[] = [];
  subClasses: SubClassResponseModel[];

  constructor(
    private dialogRef: MatDialogRef<SubClassDialog>,
    private viewModel: SubClassViewModel,
    private _snackBar: SnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.LoadData();
  }


  async LoadData() {
    if (this.data && this.data.length > 0) {
      this.toggleValue = this.data[0].id;
      this.previousToggleValue = this.toggleValue;
      this.GetSubClassesByClassId(this.data[0].id);
    }
    else {
      this.dialogRef.close();
      this._snackBar.ShowWarning("Classes not found");
    }
  }



  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: SubClassResponseModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.basicNumber + 1}`;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  SetSubCourses() {
    this.FillClassRequestModel(this.toggleValue);
    LoggerHelper.log(this.classesRequest);

    this.dialogRef.close(this.classesRequest);
  }



  FillClassRequestModel(classId) {
    var classRequest = new ClassRequestModel();
    classRequest.id = classId;
    classRequest.name = this.data.find(f => f.id == classId).name;
    classRequest.isPrimary = this.data.find(f => f.id == classId).isPrimary;
    classRequest.subClasses = [];
    this.selection.selected.forEach(element => {
      var subClass = new SubClassRequestModel();
      subClass.id = element.id;
      subClass.name = element.name
      classRequest.subClasses.push(subClass);
    });

    this.classesRequest.push(classRequest);
  }

  CheckIfClassesRequestAlreadySet(event: number) {
    if (this.classesRequest && this.classesRequest.length > 0) {
      var classRequest = this.classesRequest.find(f => f.id == event);
      if (classRequest) {
        classRequest.subClasses.forEach(element => {
          var subClass = this.subClasses.find(f => f.id == element.id);
          LoggerHelper.log(subClass);
          this.selection.toggle(subClass);
        });

        this.classesRequest.splice(this.classesRequest.indexOf(classRequest), 1);
      }
    }
  }



  ChangeMatToggle(event: any) {
    if (this.selection.selected && this.selection.selected.length > 0) {
      this.FillClassRequestModel(this.previousToggleValue);
    }

    LoggerHelper.log(this.classesRequest);

    this.previousToggleValue = event;
    this.selection.clear();
    this.GetSubClassesByClassId(event);
  }

  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  START API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------

  /**
   * @name GetSubClassesByClassId
   * Get subclasses for certain classId
   */
  async GetSubClassesByClassId(classId: number) {
    this.isLoading = true;
    await this.viewModel
      .GetSubClassesByClassId(classId)
      .toPromise()
      .then((result) => {
        LoggerHelper.log(
          'New Data inside GetSubClassesByClassId : ' + JSON.stringify(result)
        );
        this.subClasses = result;
        this.dataSource = new MatTableDataSource<SubClassResponseModel>(
          this.subClasses
        );

        this.CheckIfClassesRequestAlreadySet(classId);
      })
      .catch((error) => {
        if (error == HttpExceptionKeys.NotFound) {
          LoggerHelper.log('Error :: ' + error);
          this._snackBar.ShowWarning('Cannot found any extra service');
          this.dialogRef.close(false);
        }
        else {
          LoggerHelper.log('Error :: ' + error);
          this._snackBar.ShowWarning('There is an issue with your connection');
          this.dialogRef.close(false);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }



  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------
  //  End API Calls
  //-------------------------------------------------
  //-------------------------------------------------
  //-------------------------------------------------


}
