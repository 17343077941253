<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 preview-files-dialog">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
    <span class="title">Documents</span>
  </div>


  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 content-body">
    <mat-selection-list [multiple]="false">
      <mat-list-option (click)="OpenUrl(file.url)" *ngFor="let file of data.files" [value]="file.url">
        <mat-icon mat-list-icon style="color: grey;">insert_drive_file</mat-icon>
        {{file.clientName}}
      </mat-list-option>
    </mat-selection-list>
  </div>


  <mat-dialog-actions align="end">
    <button mat-button class="cancel" cdkFocusInitial [mat-dialog-close]=false>Cancel</button>
  </mat-dialog-actions>

</div>
