import { DocumentRequestModel } from '../../document/request/document-request.model';
import { ExtraServiceRequestModel } from '../../extra-service/request/extra-service-request.model';
import { IssueAdditionalFeeRequestModel } from '../../issue-additional-fee/request/issue-additional-fee-request.model';
import { IssueCustomFeeRequestModel } from '../../issue-custom-fee/request/issue-custom-fee-request.model';

export class IssueRequestModel {
  description: string;
  additionalDocuments?: DocumentRequestModel[];
  issueCustomFee: IssueCustomFeeRequestModel;
  extraServices?: ExtraServiceRequestModel[];
  additionalFee?: IssueAdditionalFeeRequestModel;
}
