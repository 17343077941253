import * as CryptoJS from 'crypto-js';
import { LoggerHelper } from 'src/app/core/helpers/logging/logger-helpers';
import { DecryptionHelper } from './decryption.helper';
import { EncryptionHelper } from './encryption.helper';

export class EncryptionDecryptionHelper {

  // private static readonly encryptSecretKey: string = 'RAZp?w#cyp#kBe_$P@S5w0rD$_4Sk_$@UGMENT@l$_knTSA1Qd20KhX+fH7!AlT@R!k'

  // public static EncryptData(data) {
  //   try {
  //     return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
  //   } catch (e) {
  //     LoggerHelper.log(e);
  //   }
  // }

  // public static DecryptData(data) {
  //   try {
  //     const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
  //     if (bytes.toString()) {
  //       return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //     }
  //     return data;
  //   } catch (e) {
  //     LoggerHelper.log(e);
  //   }
  // }




  /**
   * Decrypt function common with mobile
   * @param key
   * @param iv
   * @param cipherText
   */
  public static DecryptStringAES(
    key: string,
    iv: string,
    cipherText: any
  ) {
    var decryptedText = DecryptionHelper.DecryptText_Aes_Advanced(
      cipherText,
      key,
      iv,
      CryptoJS.mode.CBC,
      CryptoJS.pad.Pkcs7,
      128 / 8
    );
    LoggerHelper.log(decryptedText);
    return decryptedText;
  }



  /**
   * Encrypt function common with content studio
   * @param key
   * @param iv
   * @param normalText
   */
  public static EncryptStringAES(key: string, iv: string, normalText: any) {
    var encryptedText = EncryptionHelper.EncryptStringAES_Advanced(
      normalText,
      key,
      iv,
      CryptoJS.mode.CBC,
      CryptoJS.pad.Pkcs7,
      128 / 8
    );
    LoggerHelper.log(encryptedText);
    return encryptedText;
  }

}
