import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-calls/global-variables';
import { HttpCall } from '../../http/http-calls/http-calls';

@Injectable({
  providedIn: 'root'
})
export class ExtraServicesApplicationService {

  constructor(
    private globalApiVariables: GlobalApiVariables,
    private http: HttpCall) {
  }




  /**
   * @name GetAllAvailableExtraServices
   * Get all available extra services
   */
  public GetAllAvailableExtraServices() {
    var data = this.http.GetAsync(
      true,
      this.globalApiVariables.ApiGetAllAvailableExtraServices,
    );
    return data;
  }


  /**
   * @name CreateExtraService
   * @param body
   * Create extra service
   */
  public CreateExtraService(body: any) {
    var data = this.http.PostAsync(
      true,
      body,
      this.globalApiVariables.ApiCreateExtraServices
    );
    return data;
  }



  /**
   * @name DeleteExtraService
   * @param extraServiceId
   * Delete extra service
   */
  public DeleteExtraService(extraServiceId: number) {
    var data = this.http.DeleteAsync(
      true,
      this.globalApiVariables.ApiDeleteExtraService,
      extraServiceId
    );

    return data;
  }


  /**
   * @name GetExtraServiceById
   * @param extraServiceId
   * Get extra service by id
   */
  public GetExtraServiceById(extraServiceId: number) {
    var data = this.http.GetAsync(
      true,
      this.globalApiVariables.ApiGetExtraServiceById,
      extraServiceId
    );

    return data;
  }


  /**
   * @name UpdateExtraService
   * @param id
   * @param body
   * Update extra service
   */
  public UpdateExtraService(id: number, body: any) {
    var data = this.http.PutAsync(
      true,
      body,
      this.globalApiVariables.ApiUpdateExtraService,
      id
    );

    return data;
  }

}
