import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-calls/global-variables';
import { HttpCall } from '../../http/http-calls/http-calls';

@Injectable({
  providedIn: 'root'
})
export class RequestTypesApplicationService {

  constructor(
    private globalApiVariables: GlobalApiVariables,
    private http: HttpCall) {
  }



  /**
   * @name GetRequestTypes
   * get all request types
   */
  public GetRequestTypes() {
    var data = this.http.GetAsync(
      true,
      this.globalApiVariables.ApiGetRequestTypes
    );

    return data;
  }


  /**
   * @name GetPaidRequestType
   * @returns data
   * Get paid request types
   */
  public GetPaidRequestType() {
    var data = this.http.GetAsync(
      true,
      this.globalApiVariables.ApiGetPaidRequestTypes
    );

    return data;
  }




}
