import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { MainKeys } from 'src/app/shared/helpers/main-keys';
import { LoggerHelper } from '../../helpers/logging/logger-helpers';
import { UrlFormatterHelper } from '../../helpers/url-formatter/url-formatter-helper';
import { UserSessionResponseModel } from '../../models/user-session/response/user-session-response.model';
import { HttpExceptionKeys } from './http-exception-keys';


@Injectable({
  providedIn: 'root',
})

export class HttpCall {
  constructor(
    private http: HttpClient,
    //private userSessionApplicationService: UserSessionApplicationService
  ) { }

  TIME_OUT_VALUE = 30000; //30s


  /**
   * @name formatUrl
   * @param url
   * @param args
   * Format url function
   */
  formatUrl(url: string, args: any[]): string {
    if (args && args.length > 0) {
      return UrlFormatterHelper.FormatString(url, args);
    } else {
      return url;
    }
  }



  /**
   * @name GetUserToken
   * Get user token
   */
  private GetUserToken(): string {
    if (UserSessionResponseModel && UserSessionResponseModel.Instance.accessToken) {
      // LoggerHelper.log('Token : ' + UserSessionModel.Instance.session.token);
      return UserSessionResponseModel.Instance.accessToken;
    } else {
      return '';
    }
  }



  /**
   * @name AddHeaderToHttp
   * @param addAuthorization
   * Add http header to the api calls function
   */
  AddHeaderToHttp(addAuthorization: boolean): any {
    let options;
    if (addAuthorization == true) {
      options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.GetUserToken()
        }),
        //reportProgress: true,
      };
    } else {
      options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
        //reportProgress: true,
      };
    }
    return options;
  }



  /**
   * @name AddHeaderToHttp
   * @param addAuthorization
   * Add http header to the api calls function
   */
  AddHeaderToHesabe(): any {
    let options;
    options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'text',
        accessCode: MainKeys.accessCode
      }),
      responseType: 'text'
      //reportProgress: true,
    };

    return options;
  }




  /* GET async function */
  /**
   * @name GetAsync
   * @param addAuthentication
   * @param url
   * @param args
   * Get request
   */
  GetAsync(
    addAuthentication: boolean,
    url: string,
    ...args: any[]
  ): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http url to send ' + fullUrl);
    try {
      return this.http
        .get(fullUrl, this.AddHeaderToHttp(addAuthentication))
        .pipe(
          timeout(this.TIME_OUT_VALUE),
          map((data: any) => {
            return data;
          }), catchError(error => {
            return this.handleError(error);
          })
        );
      // .timeout(this.TIME_OUT_VALUE)
      // .catch((err: HttpErrorResponse) => {
      //   return this.handleError(err);
      // })
      // .map((response: any) => {
      //   LoggerHelper.log('http response ' + JSON.stringify(response));
      //   return response;
      // });
    } catch (Excpetion) { }
  }



  /**
   * @name PostAsync
   * @param addAuthentication
   * @param body
   * @param url
   * @param args
   * Post request
   */
  PostAsync(
    addAuthentication: boolean,
    body: any,
    url: string,
    ...args: any[]
  ): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http url to send ' + fullUrl);
    LoggerHelper.log('http body to send ' + body);

    try {
      return this.http
        .post(fullUrl, body, this.AddHeaderToHttp(addAuthentication))
        .pipe(
          timeout(this.TIME_OUT_VALUE),
          map((data: any) => {
            return data;
          }), catchError(error => {
            return this.handleError(error);
          })
        );
    } catch (Excpetion) { }
  }



  /**
   * @name PutAsync
   * @param addAuthentication
   * @param body
   * @param url
   * @param args
   * Put request
   */
  PutAsync(
    addAuthentication: boolean,
    body: any,
    url: string,
    ...args: any[]
  ): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http url to send ' + fullUrl);
    LoggerHelper.log('http body to send ' + body);

    try {
      return this.http
        .put(fullUrl, body, this.AddHeaderToHttp(addAuthentication))
        .pipe(
          timeout(this.TIME_OUT_VALUE),
          map((data: any) => {
            return data;
          }), catchError(error => {
            return this.handleError(error);
          })
        );
    } catch (Excpetion) { }
  }


  /**
   * @name PatchAsync
   * @param addAuthentication
   * @param body
   * @param url
   * @param args
   * Patch request
   */
  PatchAsync(
    addAuthentication: boolean,
    body: any,
    url: string,
    ...args: any[]
  ): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http url to send ' + fullUrl);
    LoggerHelper.log('http body to send ' + body);

    try {
      return this.http
        .patch(fullUrl, body, this.AddHeaderToHttp(addAuthentication))
        .pipe(
          timeout(this.TIME_OUT_VALUE),
          map((data: any) => {
            return data;
          }), catchError(error => {
            return this.handleError(error);
          })
        );
    } catch (Excpetion) { }
  }

  /**
   * @name DeleteAsync
   * @param addAuthentication
   * @param url
   * @param args
   * Patch request
   */
  DeleteAsync(
    addAuthentication: boolean,
    url: string,
    ...args: any[]
  ): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http url to send ' + fullUrl);

    try {
      return this.http
        .delete(fullUrl, this.AddHeaderToHttp(addAuthentication))
        .pipe(
          timeout(this.TIME_OUT_VALUE),
          map((data: any) => {
            return data;
          }), catchError(error => {
            return this.handleError(error);
          })
        );
    } catch (Excpetion) { }
  }

  /**
   * @name Request
   * @param method "POST" | "PUT" | "PATCH" | "DELETE" | "GET"
   * @param addAuthentication
   * @param body
   * @param url
   * @param args
   * Request function could send get,put,post,delete http request by choosing the methods.
   * The methods are :  "POST" | "PUT" | "PATCH" | "DELETE" | "GET"
   */
  Request(
    method: string,
    addAuthentication: boolean,
    body: any,
    url: string,
    ...args: any[]
  ): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http url to send ' + fullUrl);
    LoggerHelper.log('http body to send ' + body);

    try {
      var option = this.AddHeaderToHttp(addAuthentication);
      option['body'] = body;
      return this.http
        .request(method, fullUrl, option)
        .pipe(
          timeout(this.TIME_OUT_VALUE),
          map((data: any) => {
            return data;
          }), catchError(error => {
            return this.handleError(error);
          })
        );
    } catch (Excpetion) { }
  }



  /**
   * @name PostFilesAsync
   * @param addAuthentication
   * @param formData
   * @param url
   * @param args
   * Post request
   */
  PostFilesAsync(
    addAuthentication: boolean,
    formData: FormData,
    url: string,
    ...args: any[]
  ): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http url to send ' + fullUrl);

    try {
      return this.http
        .post(fullUrl, formData, {
          headers: {
            Authorization: 'Bearer ' + this.GetUserToken()
          },
          reportProgress: true,
          observe: 'events',
        }).pipe(
          timeout(this.TIME_OUT_VALUE),
          map((data: any) => {
            return data;
          }), catchError(error => {
            return this.handleError(error);
          })
        );
    } catch (Excpetion) { }
  }





  /**
   * @name PostWithFromDataAsync
   * @param addAuthentication
   * @param formData
   * @param url
   * @param args
   * Post request
   */
  PostWithFromDataAsync(
    addAuthentication: boolean,
    formData: FormData,
    url: string,
    ...args: any[]
  ): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http url to send ' + fullUrl);

    try {
      return this.http
        .post(fullUrl, formData, {
          headers: {
            Authorization: 'Bearer ' + this.GetUserToken()
          },
          reportProgress: true,
          observe: 'events',
        }).pipe(
          timeout(this.TIME_OUT_VALUE),
          map((data: any) => {
            return data;
          }), catchError(error => {
            return this.handleError(error);
          })
        );
    } catch (Excpetion) { }
  }




  /**
   * @name PostAsync
   * @param addAuthentication
   * @param body
   * @param url
   * @param args
   * Post request
   */
  PostHesabeAsync(
    addAuthentication: boolean,
    body: any,
    url: string,
    ...args: any[]
  ): Observable<any> {
    var fullUrl = this.formatUrl(url, args);
    LoggerHelper.log('http url to send ' + fullUrl);
    LoggerHelper.log('http body to send ' + body);

    try {
      return this.http
        .post(fullUrl, body, this.AddHeaderToHesabe())
        .pipe(
          timeout(this.TIME_OUT_VALUE),
          map((data: any) => {
            return data;
          }), catchError(error => {
            return this.handleError(error);
          })
        );
    } catch (Excpetion) { }
  }




  /**
   * @name handleError
   * @param error
   * Handle the http errors
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      switch (error.status) {
        case 400:
          //throw new InvalidCastException();
          LoggerHelper.logError(HttpExceptionKeys.AlreadyExists);
          return throwError(HttpExceptionKeys.AlreadyExists);
        case 401:
          //UnAuthorized
          LoggerHelper.logError(HttpExceptionKeys.UnAuthorized);
          //this.userSessionApplicationService.ClearSessionData();
          return throwError(HttpExceptionKeys.UnAuthorized);
        case 403:
          //UnAuthorized
          LoggerHelper.logError(HttpExceptionKeys.UnAuthorized);
          return throwError(HttpExceptionKeys.UnAuthorized);
        case 404:
          //throw new KeyNotFoundException();
          LoggerHelper.logError(HttpExceptionKeys.NotFound);
          return throwError(HttpExceptionKeys.NotFound);
        case 409:
          //Conflict
          LoggerHelper.logError(HttpExceptionKeys.Conflict);
          return throwError(HttpExceptionKeys.Conflict);
        case 412:
          //throw new InvalidOperationException();
          LoggerHelper.logError(HttpExceptionKeys.Conflict);
          return throwError(HttpExceptionKeys.Conflict);
        case 422:
          //throw new InvalidOperationException();
          LoggerHelper.logError(HttpExceptionKeys.IncorrectRequestData);
          return throwError(HttpExceptionKeys.IncorrectRequestData);
        case 405:
          //throw new InvalidOperationException();
          LoggerHelper.logError(HttpExceptionKeys.MethodNotFound);
          return throwError(HttpExceptionKeys.MethodNotFound);
        case 503:
          //ServiceUnavailable
          LoggerHelper.logError(HttpExceptionKeys.ServiceUnavailable);
          return throwError(HttpExceptionKeys.ServiceUnavailable);
        default:
          LoggerHelper.logError(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`
          );
          return throwError(error.error.message);
      }
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

}
