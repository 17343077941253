import { CachingHelper } from 'src/app/core/helpers/caching/caching';
import { CachingKeys } from 'src/app/core/helpers/caching/caching-keys';
import { SerializationHelper } from 'src/app/core/helpers/serialization/serialization-helper';

export class UserSessionResponseModel {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  countryIsoCode: string;
  internationalPhoneCode: string;
  phone: string;
  isVerified: boolean;
  isAdmin: boolean;
  accessToken: string;


  public static _instance: UserSessionResponseModel;



  public static get Instance() {
    if (this._instance == null) {
      this._instance = SerializationHelper.toInstance(
        new UserSessionResponseModel(),
        CachingHelper.getItemFromCache(CachingKeys.UserSession)
      );
      return this._instance;
    }
    return this._instance;
  }


  public static ClearInstance() {
    this._instance = null;
  }


  public static UpdateInstance(data: UserSessionResponseModel) {
    CachingHelper.saveItemIntoCache(
      CachingKeys.UserSession,
      JSON.stringify(data)
    );
  }
}
