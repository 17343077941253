import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NavigationKeys } from '../helpers/navigation-keys';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  year: number = new Date().getFullYear();
  constructor(private _router : Router) { }

  ngOnInit(): void {
  }


  navigateToComprehensiveAdvisory(){
    this._router.navigate([NavigationKeys.ComprehensiveStudyPage]);
  }


  navigateToRegistrationRequest() {
    this._router.navigate([NavigationKeys.RegistrationRequestPage]);
  }



  navigateToTrademarkWatch() {
    this._router.navigate([NavigationKeys.TrademarkWatchInfoPage]);
  }

  navigateToLandingPage(){
    this._router.navigate([NavigationKeys.LandingPage]);
  }


  navigateToAboutUs() {
    this._router.navigate([NavigationKeys.AboutUsPage]);
  }


  navigateToPrices() {
    this._router.navigate([NavigationKeys.PricePage]);
  }


  navigateToContactUs() {
    this._router.navigate([NavigationKeys.ContactUsPage]);
  }
}
