export class SidebarHandlerModel {
  dashboard: boolean = false;
  comprehensiveStudy: boolean = false;
  registrationRequest: boolean = false;
  certificationRequest: boolean = false;
  renewalCertificate: boolean = false;
  discount: boolean = false;
  searches: boolean = false;
  trademarkTransfer: boolean = false;
  country: boolean = false;
  user: boolean = false;
  extraService: boolean = false;
  watchPackage: boolean = false;
  emailRequest: boolean = false;
}
