import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'add-document-dialog',
  templateUrl: './add-document-dialog.component.html',
  styleUrls: ['./add-document-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class AddDocumentDialog {
  constructor(
    public dialogRef: MatDialogRef<AddDocumentDialog>) {
  }

  name: string;
  description: string;



  AddDocument() {
    this.dialogRef.close({
      name: this.name,
      description: this.description
    })
  }

}
