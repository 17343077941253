import { Injectable } from '@angular/core';
import { CountriesApplicationService } from '../../services/countries/countries-application-service.service';
import { RequestTypesApplicationService } from '../../services/request-types/request-types-application-service.service';


@Injectable({
  providedIn: 'root'
})

export class CountryViewModel {

  constructor(
    private service: CountriesApplicationService,
    private requestTypeService: RequestTypesApplicationService) {
  }


  /**
   * @name CreateCountry
   * @param body
   * Create country
   */
  public CreateCountry(body: any) {
    var data = this.service.CreateCountry(body);
    return data;
  }



  /**
   * @name UpdateCountry
   * @param countryId
   * @param body
   * Update country
   */
  public UpdateCountry(countryId: number, body: any) {
    var data = this.service.UpdateCountry(countryId, body);
    return data;

  }


  /**
   * @name GetAllCountries
   * Get all countries
   */
  public GetAllCountries() {
    var data = this.service.GetAllCountries();
    return data;
  }



  /**
   * @name GetCountryDetails
   * @param countryId
   * Get country details
   */
  public GetCountryDetails(countryId: number) {
    var data = this.service.GetCountryDetails(countryId);
    return data;
  }



  /**
   * @name DeleteCountry
   * @param countryId
   * @returns data
   * Delete country by id
   */
  public DeleteCountry(countryId: number) {
    var data = this.service.DeleteCountry(countryId);
    return data;
  }



  /**
   * @name GetPaidRequestType
   * @returns data
   * Get paid request types
   */
  public GetPaidRequestType() {
    var data = this.requestTypeService.GetPaidRequestType();
    return data;
  }

}
