<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 issue-details-view">

  <!-- issue description -->
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Issue</h4>
    </div>

    <div class="col-9 p-0">
      <h4 class="info-value">{{issue.description}}</h4>
    </div>
  </div>

  <!-- issue custom fee -->
  <div *ngIf="issue.isCustomFee" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
    <div class="col-3 p-0">
      <h4 class="key">Sub total</h4>
    </div>

    <div class="col-9 p-0">
      <h4 class="info-value">{{issue.issueCustomFee.subtotal}} USD $</h4>
    </div>

    <ng-container *ngIf="issue.issueCustomFee.discount > 0">
      <div class="col-3 p-0">
        <h4 class="key">Discount</h4>
      </div>

      <div class="col-9 p-0">
        <h4 class="info-value">{{issue.issueCustomFee.discount}} USD $</h4>
      </div>
    </ng-container>


    <div class="col-3 p-0">
      <h4 class="key">Total</h4>
    </div>

    <div class="col-9 p-0">
      <h4 class="info-value">{{issue.issueCustomFee.total}} USD $</h4>
    </div>
  </div>


  <div *ngIf="issue.issueExtraServices && issue.issueExtraServices.length>0"
    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 mt-4 p-0 border-section">

    <div class="col-4">

    </div>
    <div class="col-2">
      <h4 class="key">Quantity</h4>
    </div>
    <div class="col-2">
      <h4 class="key">Discount</h4>
    </div>
    <div class="col-2">
      <h4 class="key">subTotal</h4>
    </div>
    <div class="col-2">
      <h4 class="key">Total</h4>
    </div>

    <div *ngFor="let extraService of issue.issueExtraServices" class="m-0 p-0 mt-3 mb-3 row col-12">
      <div class="col-4">
        {{extraService.description}}
      </div>
      <div class="col-2">
        {{extraService.quantity}}
      </div>
      <div class="col-2">
        {{extraService.discount}}$
      </div>
      <div class="col-2">
        {{extraService.subTotal}}$
      </div>
      <div class="col-2">
        {{extraService.totalFee}}$
      </div>
    </div>

  </div>


  <div *ngIf="issue.additionalFee" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-5">
    <h3>Additional fee</h3>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
      <div class="col-3 p-0">
        <h4 class="key">Description</h4>
      </div>

      <div class="col-9 p-0">
        <h4 class="info-value">{{issue.additionalFee.description}}</h4>
      </div>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 row m-0 p-0 mt-3">
      <div class="col-3 p-0">
        <h4 class="key">fee</h4>
      </div>

      <div class="col-9 p-0">
        <h4 class="info-value">{{issue.additionalFee.fee}} USD $</h4>
      </div>
    </div>
  </div>


  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-4">
    <h3 class="issue-description">Additional information</h3>
    <span>If the company is asking for additional information please provide them here</span>
  </div>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 p-0">
    <mat-form-field class="text-area">
      <mat-label>description</mat-label>
      <textarea rows="2" matInput [(ngModel)]="description" name="description area"></textarea>
    </mat-form-field>
  </div>

  <div *ngIf="issue.documents && issue.documents.length > 0"
    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-4">
    <h3 class="issue-description">Additional documents</h3>
    <span>The company is asking for additional files please provide them before submitting</span>
  </div>

  <div *ngIf="issue.documents && issue.documents.length > 0"
    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-4">
    <div *ngFor="let document of issue.documents" class="m-0 p-0">
      <span class="document-name">{{document.name}}: </span>
      <span *ngIf="document.description">{{document.description}}</span>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4 p-0 dropzone">
        <drop-zone (addDocument)="AddDocuments($event)" (removeDocument)="RemoveDocuments($event)"
          [name]="document.name"></drop-zone>
      </div>
    </div>
  </div>

</div>
